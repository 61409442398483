import { Col, Collapse, Row, Select } from 'antd';
import { translateX } from 'utils/helpers';
import appmultiService from 'services/appmultiService';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { memo } from 'react';

const QuotationsFilter = memo(props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    dataListing,
    classification,
    setClassification,
    openFilter,
    setOpenFilter,
    setFilteredData,
    travelProfile,
    setTravelProfile,
    operator,
    setOperator,
  } = props;

  // local variables I ---------------------------------------------------------
  const travelProfileOptions = appmultiService.getTravelProfiles();
  const operatorsOptions = appmultiService.getOperatorsFromPlans(dataListing);

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onChangeClassification = value => {
    const list = appmultiService.sorterPlans(dataListing, {
      classification: value,
      profile: travelProfile,
      operator,
    });

    setFilteredData(list);
    setClassification(value);
  };

  const onChangeTravelProfile = value => {
    const list = appmultiService.sorterPlans(dataListing, {
      classification,
      profile: value,
      operator,
    });

    console.log('list', list)

    setFilteredData(list);
    setTravelProfile(value);   
  };


  const onChangeOperator = value => {
    const list = appmultiService.sorterPlans(dataListing, {
      classification,
      profile: travelProfile,
      operator: value,
    });

    setFilteredData(list);
    setOperator(value);
  };


  // local variables III ------------------------------------------------------
  const filterItems = buildFilter();

  // UI COMPONENT -------------------------------------------------------------
  return (
    <div
      style={{
        maxWidth: 1000,
        width: '100%',
        paddingLeft: 18,
        paddingRight: 18,
      }}
    >
      {/* FILTER */}
      {/*{dataListing?.length > 0 ? (*/}
      <Collapse
        defaultActiveKey={openFilter ? 'additional_fields' : null}
        size="large"
        ghost
        items={filterItems}
        className="no-collapse-padding"
        onChange={() => setOpenFilter(!openFilter)}
      />
      {/*) : null}*/}
      {/* FILTER */}
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildFilter() {
    return [
      {
        key: 'additional_fields',
        label: (
          <span className="text-uppercase text-muted">
            {translateX('filter_data')}
          </span>
        ),
        children: (
          <Row gutter={ROW_GUTTER}>
            {/* SEGURADORA */}
            <Col xs={24} sm={8}>
              <Select
                className="mb-3 w-100"
                size="large"
                allowClear
                placeholder="Operadora"
                optionFilterProp="children"
                value={operator}
                onChange={onChangeOperator}
                options={operatorsOptions || []}
              />
            </Col>
            {/* SEGURADORA */}

            {/* TRAVEL PROFILE */}
            <Col xs={24} sm={8}>
              <Select
                className="mb-3 w-100"
                allowClear
                size="large"
                placeholder="Tipo de Viagem"
                optionFilterProp="children"
                value={travelProfile}
                onChange={onChangeTravelProfile}
                options={travelProfileOptions || []}
              />
            </Col>
            {/* TRAVEL PROFILE */}

            {/* CLASSIFICATION */}
            <Col xs={24} sm={8}>
              <Select
                className="mb-3 w-100"
                size="large"
                placeholder="Classificação"
                optionFilterProp="children"
                value={classification}
                onChange={onChangeClassification}
                options={[
                  {
                    value: 'best_seller',
                    label: 'Mais Vendido',
                  },
                  {
                    value: 'cheaper',
                    label: 'Menor Preço',
                  },
                  {
                    value: 'more_expansive',
                    label: 'Maior Preço',
                  },
                ]}
              />
            </Col>
            {/* CLASSIFICATION */}
          </Row>
        ),
      },
    ];
  }
});

export default QuotationsFilter;
