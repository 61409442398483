import { Card, Col, Row } from 'antd';
import JarvislyEntityForm from 'components/jarvisly-components/forms/Items/JarvislyEntityForm';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import {
  JarvislyDatePicker,
  JarvislyDateRangePicker,
  JarvislyInput,
  JarvislyTextArea,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const EventsRecordIndex = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  useTabChild('record', ['name'], props);

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} md={12}>
            <JarvislyInput name="name" />
          </Col>
          <Col xs={24} md={12}>
            <JarvislyDatePicker label="event_date" name="eventDate" />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24}>
            <JarvislyTextArea name="description" />
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default EventsRecordIndex;
