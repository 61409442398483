import { Tabs } from 'antd';
import { returnOnlyNumbers, translateWord } from 'utils/helpers';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import CustomersRecordIndex from './Record/RecordIndex';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import JarvislyDocumentStatusModal from 'components/jarvisly-components/forms/modals/JarvislyDocumentStatusModal';
import { useSelector } from 'react-redux';
import { clone } from 'lodash';
import useTabParent from 'components/jarvisly-components/hooks/useTabParentHook';
import ParametersProvider from 'components/jarvisly-module/ParametersProvider';
import ContactModal from './EmergencyContact/ContactModal';
import ContactTab from './EmergencyContact/ContactTab';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const CustomersForm = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, setRequiredFields, requiredFields, mode } = props;

  // local variables I ---------------------------------------------------------
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'contacts', 'questionnaire'];
  const documentStatusModalRef = useRef(null);
  const { parameters } = useSelector(s => s.moduleSlice);

  // component states ----------------------------------------------------------
  const [tab, setTab] = useTabParent(availableTabs, 'name');
  const [formData] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [entityType, setEntityType] = useState('company');
  const [showContactsModal, setShowContactsModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    activeTab: tab,
    initTab,

    selectedEntity,
    setSelectedEntity,
    entityType,
    setEntityType,

    formData,
    selectedRecord, setSelectedRecord,
    showContactsModal, setShowContactsModal
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  // useEffect(() => {
  //   if (!availableTabs.includes(tab)) setTab(initTab);
  //   url.updateUrlQueryParams({ tab }, true);
  //   setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'cpf' }));
  // }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const parameterRequiredFields = clone(parameters?.requiredFields || []);

    if (Array.isArray(parameterRequiredFields)) {
      if (parameterRequiredFields.includes('cpfCnpj')) {
        parameterRequiredFields.push('cpf', 'cnpj');
      }
      if (parameterRequiredFields.includes('address')) {
        parameterRequiredFields.push('zip', 'number');
      }
    }

    setRequiredFields([
      ...parameterRequiredFields,
      'name',
      'fullName',
    ]);
  }, [parameters?.requiredFields]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    documentStatusModalRef,
  }));

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;

    // decorate entity
    // body.dataType = 'company';
    body.dataProfile = 'customer';
    body.uniqueKey = returnOnlyNumbers(body.cpf) || +new Date();
    body.status = 'new';

    return body;
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      {/* DOCUMENT STATUS MODAL WRAPPER */}
      <JarvislyDocumentStatusModal
        {...childProps}
        ref={documentStatusModalRef}
      />
      {/* DOCUMENT STATUS MODAL WRAPPER */}

      {/* CONTACT MODAL WRAPPER */}
      <ContactModal {...childProps}/>
      {/* CONTACT MODAL WRAPPER */}

      <ParametersProvider>
        {/* WRAPPER MODULE PARAMETERS MODAL */}
        {selectedModule?.components?.parameters
          ? selectedModule.components.parameters(childProps)
          : null}
        {/* WRAPPER MODULE PARAMETERS MODAL */}

        <JarvislyFormProvider
          document={document}
          requiredFields={requiredFields}
          setTab={setTab}
          onSave={onSave}
          // onValuesChange={(v, allValues) => setFormData({ ...allValues })}
        >
          <Tabs
            type="line"
            tabPosition="top"
            activeKey={tab}
            rootClassName="tab-in-form"
            items={tabItems.filter(x => !x.hide)}
            onTabClick={onTabClick}
          />
        </JarvislyFormProvider>
      </ParametersProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateWord('record'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <CustomersRecordIndex {...childProps} />
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateWord('contacts'),
        key: 'contacts',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab='contacts'>
            <ContactTab {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
      // {
      //   label: translateWord('questionnaire'),
      //   key: 'questionnaire',
      //   hide: false,
      //   disabled: mode === 'add',
      //   children: (
      //     <JarvislyTabProvider tab='questionnaire'>
      //       <Questionnaire {...childProps} activeTab={tab} />
      //     </JarvislyTabProvider>
      //   ),
      // },
    ];
  }
});

export default CustomersForm;
