import React, { memo } from 'react';
import { Drawer, Segmented } from 'antd';
import {
  DeleteOutlined,
  FolderOpenOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import { useModuleContext } from '../ModuleContext';
import { setLocale, translateWord } from 'utils/helpers';
import { useSelector } from 'react-redux';

// COMPONENT *******************************************************************
// *****************************************************************************

const ListFilter = props => {
  // props deconstruction ------------------------------------------------------
  const { showModal, setShowModal, apiRequest } = props;

  // local variables -----------------------------------------------------------
  const { components } = useSelector(s => s.moduleSlice);

  const { refreshList } = useModuleContext();
  const segmentOptions = buildSegmentOptions();

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onFilterDone = async (folder, moreFields = {}) => {
    let qsObj = {
      ...apiRequest.currentQs,
      pagination: {
        ...apiRequest.currentQs.pagination,
        pageNumber: 1,
      },
      ...moreFields,
    };

    // limpa os search das colunas caso exista um textSearch
    if (qsObj?.textSearch && typeof qsObj.textSearch === 'string') {
      qsObj.search = {};
    }

    if (folder) qsObj.folder = folder;

    await refreshList(qsObj);
  };

  const childProps = {
    ...props,
    onFilterDone,
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Drawer
      title={setLocale('filter')}
      width={300}
      placement="right"
      onClose={() => setShowModal(false)}
      closable={true}
      open={showModal}
    >
      {/* FOLDER */}
      <div>
        <h5 className="mb-3 text-muted">{setLocale('folder')}</h5>

        <Segmented
          block
          size="small"
          value={apiRequest?.currentQs?.folder}
          onChange={onFilterDone}
          options={segmentOptions}
        />
      </div>
      {/* FOLDER */}

      {components?.filter ? components.filter(childProps) : null}
    </Drawer>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildSegmentOptions() {
    const documentsTitle = `${translateWord('documents')}`;
    const recycleTitle = `${translateWord('recycle')}`;
    const archiveTitle = `${translateWord('archived')}`;

    return [
      {
        label: documentsTitle,
        value: 'documents',
        icon: <FolderOpenOutlined />,
      },
      {
        label: archiveTitle,
        value: 'filed',
        icon: <FolderOutlined />,
      },
      {
        label: recycleTitle,
        value: 'recycle',
        icon: <DeleteOutlined />,
      },
    ];
  }
};

export default memo(ListFilter);
