import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import JarvislyEntityForm from 'components/jarvisly-components/forms/Items/JarvislyEntityForm';
import { translateX } from 'utils/helpers';
import { Badge, Button, Card, Col, Row, Tooltip } from 'antd';
import {
  JarvislyDatePicker,
  JarvislyFormContext,
  JarvislyNumber,
  JarvislySelect,
  JarvislySelectAvatars,
  JarvislyTextArea,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import { EditOutlined } from '@ant-design/icons';
import { ParametersContext } from '../../../../../../components/jarvisly-module/ParametersProvider';
import dayjs from 'dayjs';

// COMPONENT *******************************************************************
// *****************************************************************************
const CustomerTab = props => {
  // providers context ---------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);
  const {
    selectedRecord,
    setSelectedRecord,
    showOriginModal,
    setShowOriginModal,
    setShowBusinessAreaModal,
    showPipelineModal,
    setShowPipelineModal,
    showBoardModal,
    setShowBoardModal,
    showTemperatureModal, setShowTemperatureModal
  } = useContext(ParametersContext);

  // props deconstruction ------------------------------------------------------
  const { document, isWorking, mode } = props;

  // local variables I ---------------------------------------------------------
  const { selectedCompany } = useSelector(s => s.authSlice);
  const { parameters } = useSelector(s => s.moduleSlice);
  const opportunityTypesOptions = buildOpportunityTypesOptions();
  const { selectedSubscription } = useSelector(s => s.authSlice);
  const isDisabled = isWorking || mode === 'view';
  const team = buildTeamSelectOptions();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    selectedCompany,

    document,
    selectedRecord,
    setSelectedRecord,
    showOriginModal,
    setShowOriginModal,
    setShowBusinessAreaModal,
    showPipelineModal,
    setShowPipelineModal,
    showBoardModal,
    setShowBoardModal,
    showTemperatureModal, setShowTemperatureModal
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onAddOrigin = () => {
    setSelectedRecord(null);
    setShowOriginModal(true);
  };
  const onEditOrigin = record => {
    setSelectedRecord(record);
    setShowOriginModal(true);
  };

  const onAddBoard = () => {
    setSelectedRecord(null);
    setShowBoardModal(true);
  };
  const onEditBoard = record => {
    setSelectedRecord(record);
    setShowBoardModal(true);
  };

  const onAddTemperature = () => {
    setSelectedRecord(null);
    setShowTemperatureModal(true);
    console.log("teste")
  };
  const onEditTemperature = record => {
    setSelectedRecord(record);
    setShowTemperatureModal(true);
  };

  const onAddPipeline = () => {
    setSelectedRecord(null);
    setShowPipelineModal(true);
  };
  const onEditPipeline = record => {
    setSelectedRecord(record);
    setShowPipelineModal(true);
  };

  const disableLaterDates = current => {
    // Pode desabilitar datas do dia atual para frente
    return current && current <= dayjs().endOf('day');
  };
  const disablePreviousDates = current => {
    // Pode desabilitar datas do dia atual para frente
    return current && current > dayjs().endOf('day');
  };

  // local variables III --------------------------------------------------------

  const originOptions = buildOriginsOptions();
  const pipelineOptions = buildPipelineOptions();
  const boardOptions = buildBoardOptions();
  const temperatureOptions = buildTemperatureOptions();

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/*CUSTOMER CARD */}
      <Card title={<div className="text-muted">{translateX('customer')}</div>}>
        <JarvislyEntityForm
          {...childProps}
          entityPersonType={
            parameters?.crmParameters?.entityPersonType || 'manual'
          }
          entityPersonDefault={
            parameters?.crmParameters?.entityPersonDefault || 'company'
          }
          context="__entity"
          originRegisteredLabel="registered_customer"
          originNewLabel="new_customer"
          searchEntityLabel="search_customer"
          selectable
          showCpfCnpjPrimary={false}
          showBusinessArea={true}
          additionalInfo="optional"

        />
      </Card>
      {/*CUSTOMER CARD */}

      <Card
        title={<div className="text-muted">{translateX('opportunity')}</div>}
      >
        <Row gutter={ROW_GUTTER}>
          <Col xs={24}>
            <JarvislyTextArea
              name="opportunity"
              label="description"
              // ref={followupRef}
              rows={2}
              // label="comment"
              disabled={isDisabled}
              // value={comment}
              // onChange={({ target: { value } }) => setComment(value)}
              required
            />
          </Col>
        </Row>

        <Row gutter={ROW_GUTTER}>
          {/* OPPORTUNITY TYPE */}
          <Col xs={8}>
            <JarvislySelect
              label="opportunity_type"
              name="opportunityType"
              options={opportunityTypesOptions}
              showSearch
              allowClear
              required
            />
          </Col>
          {/* OPPORTUNITY TYPE */}

          {/* ORIGIN */}
          <Col xs={8}>
            <JarvislySelect
              label="origin"
              name="originId"
              options={originOptions} // Usando as opções criadas com o botão de editar nas opções
              showSearch
              allowClear
              required
              x-show-add-button={true}
              x-on-add-button-fn={onAddOrigin}
              optionLabelProp="text"
            />
          </Col>
          {/* ORIGIN */}

          <Col xs={8}>
            <JarvislySelectAvatars
              label="first_contact"
              name="firstContactId"
              required
              members={team}
            />
          </Col>
        </Row>
      </Card>
      <Card title={<div className="text-muted">{translateX('lead')}</div>}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            <JarvislySelectAvatars
              label="lead_owner"
              name="leadOwnerId"
              required
              members={team}
              disabled={isDisabled}
            />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislySelectAvatars
              label="responsible"
              name="responsibleId"
              required
              members={team}
              disabled={isDisabled}
            />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyNumber
              name="approximateValue"
              label="approximate_value"
              suffixDefaultValue="brl"
              min={1}
              decimalSeparator=","
              max={9999999.99}
              addonBefore={null}
              addonAfter="R$"
              suffixStyle={{ width: 60 }}
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect
              label="temperature"
              name="temperatureId"
              options={temperatureOptions}
              showSearch
              allowClear
              required
              x-show-add-button={true}
              x-on-add-button-fn={onAddTemperature}
              optionLabelProp="text"
            />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect
              label="board"
              name="boardId"
              options={boardOptions}
              showSearch
              allowClear
              required
              x-show-add-button={true}
              x-on-add-button-fn={onAddBoard}
              optionLabelProp="text"
            />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect
              label="pipeline"
              name="pipelineId"
              options={pipelineOptions}
              showSearch
              allowClear
              required
              x-show-add-button={true}
              x-on-add-button-fn={onAddPipeline}
              optionLabelProp="text"
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyDatePicker
              label="last_interaction"
              name="lastInteraction"
              disabledDate={disablePreviousDates}
              required
              format={{
                format: 'DD/MM/YYYY',
                type: 'mask',
              }}
            />
          </Col>

          <Col xs={24} sm={8} lg={8}>
            <JarvislyDatePicker
              label="next_interaction"
              name="nextInteraction"
              disabledDate={disableLaterDates}
              required
              format={{
                format: 'DD/MM/YYYY',
                type: 'mask',
              }}
            />
          </Col>

          <Col xs={24} sm={8} lg={8}>
            <JarvislyDatePicker
              label="deadline"
              name="deadline"
              disabledDate={disableLaterDates}
              required
              format={{
                format: 'DD/MM/YYYY',
                type: 'mask',
              }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildOpportunityTypesOptions() {
    return [
      {
        value: 'sale',
        text: translateX('sale'),
        label: (
          <>
            <Badge
              dot
              style={{
                marginRight: 8,
                color: 'ffff',
                backgroundColor: '#F57C00',
                marginTop: 4,
              }}
            />
            {translateX('sale')}
          </>
        ),
        disabled: false,
      },

      {
        value: 'partner',
        text: translateX('partner'),
        label: (
          <>
            <Badge
              dot
              style={{
                marginRight: 8,
                color: 'ffff',
                backgroundColor: '#00407F',
                marginTop: 4,
              }}
            />
            {translateX('partner')}
          </>
        ),
        disabled: false,
      },
    ];
  }

  function buildOriginsOptions() {
    if (!parameters?.crmParameters?.origins?.length) {
      return [];
    }

    return parameters.crmParameters.origins.map(origin => {
      return {
        value: origin._id,
        text: origin.label, // O texto a ser mostrado no Select
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span key={origin._id}>
              <Badge
                dot
                style={{
                  marginRight: 8,
                  color: origin?.foreColor,
                  backgroundColor: origin?.backColor,
                  marginTop: 4,
                }}
              />
              {origin.label}
            </span>
            {/* Exibe o botão de edição no dropdown */}
            {mode !== 'view' && (
              <Tooltip title={translateX('edit')}>
                <Button
                  size="small"
                  className="m-2"
                  icon={<EditOutlined />}
                  onClick={() => onEditOrigin(origin)}
                />
              </Tooltip>
            )}
          </div>
        ),
        disabled: false, // Pode ser ajustado conforme a lógica de negócio
      };
    });
  }

  function buildPipelineOptions() {
    // Verifica se existem origens disponíveis
    if (!parameters?.crmParameters?.pipelines?.length) {
      return [];
    }

    return parameters.crmParameters.pipelines.map(pipeline => {
      return {
        value: pipeline._id,
        text: pipeline.label,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span key={pipeline._id}>
              <Badge
                dot
                style={{
                  marginRight: 8,
                  color: pipeline?.foreColor,
                  backgroundColor: pipeline?.backColor,
                  marginTop: 4,
                }}
              />
              {pipeline.label}
            </span>
            {mode !== 'view' && (
              <Tooltip title={translateX('edit')}>
                <Button
                  size="small"
                  className="m-2"
                  icon={<EditOutlined />}
                  onClick={() => onEditPipeline(pipeline)} // Passando o registro completo
                />
              </Tooltip>
            )}
          </div>
        ),
        disabled: false, // Pode ser ajustado conforme a lógica de negócio
      };
    });
  }
  function buildBoardOptions() {
    // Verifica se existem origens disponíveis
    if (!parameters?.crmParameters?.boards?.length) {
      return [];
    }

    return parameters.crmParameters.boards.map(board => {
      return {
        value: board._id,
        text: board.label,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span key={board._id}>
              <Badge
                dot
                style={{
                  marginRight: 8,
                  color: board?.foreColor,
                  backgroundColor: board?.backColor,
                  marginTop: 4,
                }}
              />
              {board.label}
            </span>
            {mode !== 'view' && (
              <Tooltip title={translateX('edit')}>
                <Button
                  size="small"
                  className="m-2"
                  icon={<EditOutlined />}
                  onClick={() => onEditBoard(board)} // Passando o registro completo
                />
              </Tooltip>
            )}
          </div>
        ),
        disabled: false, // Pode ser ajustado conforme a lógica de negócio
      };
    });
  }
  function buildTemperatureOptions() {
    // Verifica se existem origens disponíveis
    if (!parameters?.crmParameters?.temperatures?.length) {
      return [];
    }

    return parameters.crmParameters.temperatures.map(temperature => {
      return {
        value: temperature._id,
        text: temperature.label,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span key={temperature._id}>
              <Badge
                dot
                style={{
                  marginRight: 8,
                  color: temperature?.foreColor,
                  backgroundColor: temperature?.backColor,
                  marginTop: 4,
                }}
              />
              {temperature.label}
            </span>
            {mode !== 'view' && (
              <Tooltip title={translateX('edit')}>
                <Button
                  size="small"
                  className="m-2"
                  icon={<EditOutlined />}
                  onClick={() => onEditTemperature(temperature)} // Passando o registro completo
                />
              </Tooltip>
            )}
          </div>
        ),
        disabled: false, // Pode ser ajustado conforme a lógica de negócio
      };
    });
  }

  function buildTeamSelectOptions() {
    const team = selectedSubscription?.team || [];

    return team.map(t => {
      return {
        _id: t._id,
        name: t.name,
        avatarUrl: t.pictureUrl,
      };
    });
  }
};

export default CustomerTab;
