import { useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { ModuleContext } from '../../jarvisly-module/ModuleContext';
import useUrl from './urlHooks';
import { JarvislyFormContext } from '../others/FormComponents';

const useTabParent = (availableTabs, fieldFocus) => {
  // providers context ---------------------------------------------------------

  // providers context ---------------------------------------------------------
  const { selectedModule, setModuleFocus, _id } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const initTab = selectedModule?.documentState?.initTab;
  const url = useUrl();

  // methods -------------------------------------------------------------------

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!availableTabs.includes(tab)) setTab(initTab);

    url.updateUrlQueryParams({ tab }, true);
    setModuleFocus(prev => ({ ...prev, tab: initTab, field: fieldFocus }));
  }, [tab, _id, JarvislyFormContext]); // eslint-disable-line react-hooks/exhaustive-deps

  return [tab, setTab];
};

export default useTabParent;
