import {
  JarvislyInput,
  JarvislySelect
} from 'components/jarvisly-components/others/FormComponents';
import { Col, Descriptions, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { parseCnpjToDisplay, translateX } from 'utils/helpers';
import JarvislyContactForm from './JarvislyContactForm';
import JarvislyNoteForm from './JarvislyNoteForm';
import bankService from 'services/bankService';
import PixAddIndex from 'components/jarvisly-components/forms/Banks/PixAddIndex';

const banksDigitalOptions = bankService.getBanksOptions('digital');

// COMPONENT *******************************************************************
// *****************************************************************************

const JarvislyBankOpenDigitalAccountForm = props => {
  // props deconstruction ------------------------------------------------------
  const { context, document, form } = props;

  // local variables -----------------------------------------------------------
  const record = document;
  const items = buildItems();

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <div style={props.style}>
        <Descriptions
          bordered
          items={items}
          className="w-100 mb-4"
          column={1}
        />

        <Row gutter={ROW_GUTTER}>
          {/* BANK */}
          <Col xs={24}>
            <JarvislySelect
              context={context}
              options={banksDigitalOptions}
              name="selectedBank"
              label="bank"
              onChange={value => {
                if (value === 461) {
                  form.setFieldValue('alias', 'ASAAS');
                }
              }}
              allowClear
              showSearch
            />
          </Col>
          {/* BANK */}
        </Row>

        <Row gutter={ROW_GUTTER}>
          {/* NAME */}
          <Col xs={24}>
            <JarvislyInput
              context={context}
              name="name"
              label="responsible_full_name"
            />
          </Col>
          {/* NAME */}
        </Row>

        {/* CONTACTS */}
        <JarvislyContactForm
          {...props}
          phoneProfile="mobile"
          phoneLabel="mobile"
          emailLabel="email_bank_login"
        />
        {/* CONTACTS */}

        <Row gutter={ROW_GUTTER}>
          {/* ALIAS */}
          <Col xs={24} md={8}>
            <JarvislyInput context={context} name="alias" />
          </Col>
          {/* ALIAS */}

          {/* NOTE */}
          <Col xs={24} md={16}>
            <JarvislyNoteForm context={context} {...props} />
          </Col>
          {/* NOTE */}
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} md={24}>
            <PixAddIndex {...props} />
          </Col>
        </Row>
      </div>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildItems() {
    return [
      {
        key: 'accountType',
        label: translateX('account_type'),
        span: 1,
        children: `${translateX('legal_account')}`,
      },
      {
        key: 'document',
        label: translateX('cnpj'),
        span: 1,
        children: `${parseCnpjToDisplay(record?.cnpj)}`,
      },
      {
        key: 'companyName',
        label: translateX('company_name'),
        span: 1,
        children: record?.fullName?.toUpperCase(),
      },
    ];
  }
};

export default JarvislyBankOpenDigitalAccountForm;
