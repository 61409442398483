import { useContext, useEffect, useState } from 'react';
import {
  JarvislyFormContext,
  JarvislyInput,
  JarvislySelect,
  JarvislySwitch,
  JarvislyTitle,
} from 'components/jarvisly-components/others/FormComponents';
import { Col, Flex, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import JarvislyNoteForm from './JarvislyNoteForm';
import { normalize, translateX } from 'utils/helpers';
import bankService from 'services/bankService';
import PixEditIndex from 'components/jarvisly-components/forms/Banks/PixEditIndex';

// COMPONENT *******************************************************************
// *****************************************************************************
const JarvislyBankExistingAccountForm = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    context,
    showTitle,
    selectedRecord,
    step,
  } = props;

  // local variables -----------------------------------------------------------
  const banksOptions = bankService.getBanksOptions();
  const accountTypeOptions = buildAccountTypeOptions();

  // component states ----------------------------------------------------------
  const [selectedBank, setSelectedBank] = useState(null);
  const [isDigitalAccount, setIsDigitalAccount] = useState(false);
  const [isIntegrated, setIsIntegrated] = useState(false);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (isDigitalAccount) {
      form.setFieldsValue({
        agency: '0001',
        agencyDigit: '',
        accountType: 'digital',
      });
    }
  }, [isDigitalAccount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const bankNumber = form?.getFieldValue('selectedBank');
    const bank = bankService.getBankByNumber(bankNumber);

    if (bankNumber && selectedBank?.number !== bankNumber) {
      onSelectBank(bankNumber);
    }

    if (selectedRecord?.accountType === 'digital' || bank?.isDigitalAccount) {
      setIsDigitalAccount(true);
    } else {
      setIsDigitalAccount(false);
    }
  }, [selectedRecord, step]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onSelectBank = value => {
    const bank = bankService.getBankByNumber(value);
    setIsDigitalAccount(bank.isDigitalAccount);
    setSelectedBank(bank);

    if (bank.number !== 461 && isIntegrated) {
      form.setFieldValue('apiIntegrated', false);
      setIsIntegrated(false);
    }
  };

  const onChangeIntegrated = value => setIsIntegrated(value);

  //local variables III --------------------------------------------------------
  
  // UI COMPONENTS -------------------------------------------------------------
  return (
    <div style={props.style}>
      {/* ************************************************************
      BANKS
      ************************************************************ */}
      {showTitle ? <JarvislyTitle title={'bank'} /> : null}

      <Row gutter={ROW_GUTTER}>
        {/* BANK */}
        <Col xs={24} md={16}>
          <JarvislySelect
            context={context}
            options={banksOptions}
            name="selectedBank"
            label="bank"
            allowClear
            showSearch
            disabled={selectedRecord?.apiIntegrated}
            onChange={onSelectBank}
          />
        </Col>
        {/* BANK */}

        {/* ACCOUNT TYPE */}
        <Col xs={24} md={8}>
          <JarvislySelect
            context={context}
            options={accountTypeOptions}
            name="accountType"
            label="account_type"
            allowClear
            showSearch
            disabled={isDigitalAccount}
          />
        </Col>
        {/* ACCOUNT TYPE */}
      </Row>

      {selectedBank?.number === 461 ? (
        <Flex align="center">
          <div className="mr-3" style={{ marginBottom: 24, marginLeft: 4 }}>
            Conta Integrada
          </div>
          <div>
            <JarvislySwitch
              name="apiIntegrated"
              noLabel
              onChange={onChangeIntegrated}
            />
          </div>
        </Flex>
      ) : null}

      {/* API KEY */}
      <Row
        align="middle"
        className="w-100"
        style={{ display: isIntegrated ? 'flex' : 'none' }}
      >
        <Col span={24}>
          <JarvislyInput
            context={context}
            name="apiKey"
            label="api_key"
            // style={{ flex: 1 }} // O Input ocupará o máximo possível
            required={isIntegrated}
          />
        </Col>

        {/*<Col span={4} className="text-right">*/}
        {/*  <Tooltip title={translateX('validate_key')}>*/}
        {/*    <Button*/}
        {/*      type="primary"*/}
        {/*      icon={<CheckOutlined />}*/}
        {/*      onClick={onClickValidateApiKey}*/}
        {/*    />*/}
        {/*  </Tooltip>*/}
        {/*</Col>*/}
      </Row>
      {/* API KEY */}

      {/* ACCOUNT */}
      <Row
        gutter={ROW_GUTTER}
        align="middle"
        style={{ display: !isIntegrated ? 'flex' : 'none' }}
      >
        {/* AGENCY */}
        <Col xs={16} md={8}>
          <JarvislyInput
            context={context}
            name="agency"
            disabled={isDigitalAccount}
            required={!isIntegrated}
          />
        </Col>
        {/* AGENCY */}

        {/* AGENCY DIGIT */}
        {!isDigitalAccount ? (
          <Col xs={8} md={4}>
            <JarvislyInput context={context} name="agencyDigit" label="digit" />
          </Col>
        ) : null}
        {/* AGENCY DIGIT */}

        {/* ACCOUNT */}
        <Col xs={isDigitalAccount ? 24 : 16} md={isDigitalAccount ? 12 : 8}>
          <JarvislyInput
            context={context}
            name="account"
            disabled={selectedRecord?.apiIntegrated}
            required={!isIntegrated}
          />
        </Col>
        {/* ACCOUNT */}

        {/* ACCOUNT DIGIT */}
        <Col xs={8} md={4}>
          <JarvislyInput
            context={context}
            name="accountDigit"
            label="digit"
            disabled={selectedRecord?.apiIntegrated}
            required={!isIntegrated}
          />
        </Col>
        {/* ACCOUNT DIGIT */}
      </Row>
      {/* ACCOUNT */}

      {/* TITLE */}
      <Row gutter={ROW_GUTTER}>
        {/* ALIAS */}
        <Col xs={24} md={8}>
          <JarvislyInput context={context} name="alias" />
        </Col>
        {/* ALIAS */}

        {/* NOTE */}
        <Col xs={24} md={16}>
          <JarvislyNoteForm context={context} {...props} />
        </Col>
        {/* NOTE */}
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24}>
          <PixEditIndex {...props} />
        </Col>
      </Row>
      {/* TITLE */}
    </div>
  );

  function buildAccountTypeOptions() {
    return [
      {
        value: 'conventional',
        text: normalize(translateX('conventional'), 'lower'),
        label: translateX('conventional'),
        tooltipText: translateX('conventional_account'),
        disabled: false,
      },
      {
        value: 'digital',
        text: normalize(translateX('digital'), 'lower'),
        label: translateX('digital'),
        tooltipText: translateX('digital_account'),
        disabled: false,
      },
    ];
  }
};

export default JarvislyBankExistingAccountForm;
