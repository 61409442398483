import { getFromLocalStorage } from 'utils/helpers';
import { DefaultFormHeaderColumn1, DefaultFormHeaderCustomIcons } from 'components/jarvisly-module/Form/FormHeader';
import dayjs from 'dayjs';
import EventsList from './List/List';
import EventsForm from './Form/Form';
import { MdOutlineEvent } from 'react-icons/md';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MENU_AREA = 'commercial';
// const MENU_GROUP = 'entities';
const MODULE_TITLE = 'events';
const MODULE_ICON = MdOutlineEvent;
// export const EVENTS_ROUTE = `/app/${MENU_AREA}/${MENU_GROUP}/${MODULE_TITLE}`;
export const EVENTS_ROUTE = `/app/${MENU_AREA}/${MODULE_TITLE}`;

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const EVENTS_MODULE = {
  rootName: 'events',
  name: 'events',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  urlForm: null,
  url: EVENTS_ROUTE,
  api: 'v1/events',
  routes: [`${EVENTS_ROUTE}/:_id`, EVENTS_ROUTE],

  listState: {
    showParameters: false,
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: false, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {},
    filterOptions: {},

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {},
    sorterOptions: [],

    search: {},

    textSearch: {},
  },

  methods: {
    decorateDocument,
  },

  documentState: {
    showParameters: true,
    disableLockForEdit: false,
    initTab: 'record',
    fieldName: 'name',
    newDocumentTitle: 'new_event',
    autoFill: false,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'divider',
      'document_status',
    ],
    refreshParametersOnSave: true,

    mockDocument: {
      name: 'Infodesk',
      cnpj: '02934311000153',
      fullName: 'Infodesk Technologies Ltda',
      phone: '19982320000',
      email: 'biruel@infodesk.com.br',
      zip: '13070173',
      address1: 'Avenida Marechal Rondon',
      number: '700',
      address2: 'Sala 307',
      neighborhood: 'Jardim Chapadão',
      __city: 'Campinas - SP',
      parameters: { __exchangeRate: 5.21 },
    },
  },

  components: {
    // module index
    dashboard: null,
    list: props => <EventsList {...props} />,
    grid: null,
    kanban: null,

    // module form
    form: (props, ref) => <EventsForm {...props} ref={ref} />,
    formHeader: null,
    formHeaderTitle: null,
    // formHeaderSubtitle: props => <CustomersFormHeaderSubtitle {...props} />,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    // formHeaderColumn2: props => <CustomersFormHeaderColumn2 {...props} />,
    // formHeaderColumn3: props => <CustomersFormHeaderColumn3 {...props} />,
    formHeaderTags: null,
    formHeaderCustomIcons: props => (
      <DefaultFormHeaderCustomIcons {...props} showStatusIcon />
    ),

    // module parameters
    // parameters: props => <CustomersParametersModal {...props} />,

    // data preview
    dataImport: null,
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },
};

// EXPORT ********************************************************************
// ***************************************************************************

export default EVENTS_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  if (doc?.eventDate) doc.eventDate = dayjs(doc.eventDate);

  return doc;
}
