// COMPONENT *******************************************************************
// *****************************************************************************
import { Card, Tabs } from 'antd';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { translateX } from 'utils/helpers';
import { JarvislyFormProvider, JarvislyTabProvider } from 'components/jarvisly-components/others/FormComponents';
import CouponsRecordIndex from './Record/RecordIndex';
import UseList from '../UseList/UseList';

const CouponsForm = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, mode } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const initTab = selectedModule.documentState.initTab;

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);

  // local variables II --------------------------------------------------------
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;

    body.operatorId = body.selectedOperator?.key;


    body.type = body.discountSuffix === 'R$' ? 'value' : 'percentage';

    return body;
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-coupons"
      document={document}
      autoFocus="name"
      requiredFields={['name','discount', 'qty', 'expirationDate']}
      showSubmitButton={tab !== 'contacts'}
      setTab={setTab}
      onSave={onSave}
    >
      <Tabs
        type="line"
        tabPosition="top"
        activeKey={tab}
        rootClassName="tab-in-form"
        items={tabItems.filter(x => !x.hide)}
        onTabClick={onTabClick}
      />
    </JarvislyFormProvider>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateX('record'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <CouponsRecordIndex {...props} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },

      {
        label: translateX('use'),
        key: 'use',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="contacts">
            <Card className="cad-with-body-no-padding">
              <UseList {...props} activeTab={tab} />
            </Card>
          </JarvislyTabProvider>
        ),
      },
    ];
  }
};

export default CouponsForm;
