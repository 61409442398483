import { translateX } from 'utils/helpers';
import { Card, Col, List, Row, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axiosService from 'services/axiosService';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import {
  JarvislyFormContext,
  JarvislySelect,
} from '../../../others/FormComponents';

const JarvislyEntitiesParametersRequiredFields = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------'
  const {
    requiredFields,
    setRequiredFields,
    setIsUpdated,
    showParametersModal,
    moduleName = 'entity',
  } = props;

  // local variables I ---------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);
  const moduleParameters = parameters?.[`${moduleName}Parameters`] || {};
  const initialState = buildInitialState();
  const personTypeOptions = buildPersonTypeOptions();
  const personTypeDefaultOptions = buildPersonTypeDefaultOptions();

  // component states ----------------------------------------------------------
  const [listRequiredFields, setListRequiredFields] = useState(initialState);
  const [entityPersonType, setEntityPersonType] = useState(null);
  const [parameterId, setParameterId] = useState(moduleParameters?._id);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (showParametersModal && parameterId) {
      setEntityPersonType(moduleParameters?.entityPersonType);
      form.setFieldsValue(moduleParameters);
    } else {
      onChangeEntityPerson('entityPersonType', 'manual').then();
      form.setFieldValue('entityPersonDefault', 'person');
      setIsUpdated && setIsUpdated(true);
    }
  }, [showParametersModal, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onChangeRequiredField = async (item, checked) => {
    const idx = listRequiredFields.findIndex(
      i => i.fieldName === item.fieldName,
    );
    listRequiredFields[idx].checked = checked;
    setListRequiredFields([...listRequiredFields]);

    if (checked && item.fieldName === 'phoneEmail') {
      let changed = false;

      let idx = listRequiredFields.findIndex(
        i => i.fieldName === 'phone' && i.checked,
      );

      if (idx > -1) {
        changed = true;
        listRequiredFields[idx].checked = false;
        await $save(requiredFields, listRequiredFields[idx], false);
      }

      idx = listRequiredFields.findIndex(
        i => i.fieldName === 'email' && i.checked,
      );

      if (idx > -1) {
        changed = true;
        listRequiredFields[idx].checked = false;
        await $save(requiredFields, listRequiredFields[idx], false);
      }

      if (changed) setListRequiredFields([...listRequiredFields]);
    } else if (
      checked &&
      (item.fieldName === 'phone' || item.fieldName === 'email')
    ) {
      let idx = listRequiredFields.findIndex(
        i => i.fieldName === 'phoneEmail' && i.checked,
      );

      if (idx > -1) {
        listRequiredFields[idx].checked = false;
        await $save(requiredFields, listRequiredFields[idx], false);
        setListRequiredFields([...listRequiredFields]);
      }
      setListRequiredFields([...listRequiredFields]);
    }

    const updatedRequiredFields = checked
      ? [...requiredFields, item.fieldName]
      : requiredFields.filter(f => f !== item.fieldName);

    setRequiredFields(updatedRequiredFields);
    setIsUpdated(true);
    await $save(requiredFields, item, checked);

    async function $save(requiredFields, item, checked) {
      setTimeout(async () => {
        if (!parameterId) {
          const axiosOptions = {
            url: `v1/${moduleName}-parameters`,
            data: { entityRequiredFields: requiredFields },
          };
          const result = await axiosService.post(axiosOptions);
          setParameterId(result[0]._id);
        } else {
          if (checked) {
            const url = `v1/${moduleName}-parameters/add-to-set/${parameterId}/$push/entityRequiredFields`;
            await axiosService.put({
              url,
              data: item.fieldName,
            });
          } else {
            const url = `v1/${moduleName}-parameters/add-to-set/${parameterId}/$pull/entityRequiredFields`;
            await axiosService.put({
              url,
              data: { _id: item.fieldName },
            });
          }
        }
      }, 400);
    }
  };

  // Função onChange modificada
  const onChangeEntityPerson = async (name, value) => {
    const body = { [name]: value };

    if (name === 'entityPersonType') {
      setEntityPersonType(value);

      if (value === 'person') {
        body.entityPersonDefault = 'person';
      } else if (value === 'company') {
        body.entityPersonDefault = 'company';
        // } else if (value === 'automatic') {
        //   body.entityPersonDefault = null;
      }
    }
    form.setFieldsValue(body);

    try {
      setIsUpdated(true);

      if (!parameterId) {
        const axiosOptions = {
          url: `v1/${moduleName}-parameters`,
          data: body,
        };

        const result = await axiosService.post(axiosOptions);
        setParameterId(result[0]._id);
      } else {
        const axiosOptions = {
          url: `/v1/${moduleName}-parameters/${parameterId}`,
          data: body,
        };

        await axiosService.put(axiosOptions);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Card
        title={<div className="text-muted">{translateX('data_types')}</div>}
      >
        <Row gutter={ROW_GUTTER}>
          <Col
            xs={24}
            sm={['manual', 'automatic'].includes(entityPersonType) ? 12 : 24}
          >
            <JarvislySelect
              options={personTypeOptions}
              name="entityPersonType"
              label="person_type"
              onChange={value =>
                onChangeEntityPerson('entityPersonType', value)
              } // Passa o nome e valor do select
              disabled={false}
            />
          </Col>

          <Col
            xs={24}
            sm={12}
            style={{
              display: ['manual', 'automatic'].includes(entityPersonType)
                ? 'block'
                : 'none',
            }}
          >
            {' '}
            <JarvislySelect
              options={personTypeDefaultOptions}
              name="entityPersonDefault"
              label="initial_pattern"
              onChange={value =>
                onChangeEntityPerson('entityPersonDefault', value)
              } // Passa o nome e valor do select
              disabled={false}
            />
          </Col>
        </Row>
      </Card>

      <Card
        title={
          <div className="text-muted">{translateX('required_fields')}</div>
        }
      >
        <List
          itemLayout="horizontal"
          dataSource={listRequiredFields}
          renderItem={item => (
            <List.Item key={item.fieldName}>
              <div>
                <h4 className="mb-0">{item.label}</h4>
                {/*<div>{item.description}</div>*/}
              </div>

              <div>
                <Switch
                  checked={item.checked}
                  disabled={item.disabled}
                  onChange={checked => onChangeRequiredField(item, checked)}
                />
              </div>
            </List.Item>
          )}
        />
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildInitialState() {
    const erf = moduleParameters?.entityRequiredFields;
    return [
      // {
      //   fieldName: 'cpfCnpj',
      //   label: translateX('cpf_cnpj'),
      //   description: translateX('cpf_cnpj_required_description'),
      //   checked: erf?.includes('cpfCnpj'),
      //   disabled: false,
      // },
      {
        fieldName: 'name',
        label: translateX('name_or_company'),
        // description: translateX('name_required_description'),
        checked: true,
        disabled: true,
      },
      {
        fieldName: 'businessAreaId',
        label: translateX('area_activy'),
        checked: erf?.includes('businessAreaId'),
        disabled: false,
      },
      {
        fieldName: 'fullName',
        label: translateX('full_name_or_company_name'),
        // description: translateX('name_required_description'),
        checked: erf?.includes('fullName'),
        disabled: false,
      },
      {
        fieldName: 'contactName',
        label: translateX('contact_name'),
        checked: erf?.includes('contactName'),
        disabled: false,
      },
      {
        fieldName: 'phone',
        label: translateX('phone'),
        checked: erf?.includes('phone'),
        disabled: false,
      },
      {
        fieldName: 'email',
        label: translateX('email'),
        checked: erf?.includes('email'),
        disabled: false,
      },
      {
        fieldName: 'phoneEmail',
        label: translateX('phone_or_email'),
        checked: erf?.includes('phoneEmail'),
        disabled: false,
      },
      {
        fieldName: 'address',
        label: translateX('address'),
        checked: erf?.includes('address'),
        disabled: false,
      },
    ];
  }

  function buildPersonTypeOptions() {
    return [
      {
        value: 'manual',
        label: translateX('manual'),
      },
      {
        value: 'automatic',
        label: translateX('automatic'),
      },
      {
        value: 'person',
        label: translateX('individual_person_only'),
      },
      {
        value: 'company',
        label: translateX('legal_person_only'),
      },
    ];
  }

  function buildPersonTypeDefaultOptions() {
    return [
      {
        value: 'person',
        label: translateX('individual_person'),
      },
      {
        value: 'company',
        label: translateX('legal_person'),
      },
    ];
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default JarvislyEntitiesParametersRequiredFields;
