import React from 'react';
import { Card } from 'antd';
import JarvislyBanksIndex from 'components/jarvisly-components/forms/Banks/JarvislyBanksIndex';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const CompaniesBanksTab = props => {
  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------

  return (
    <Card className="cad-with-body-no-padding">
      <JarvislyBanksIndex {...props} />
    </Card>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default CompaniesBanksTab;
