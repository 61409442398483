import { Button, Card, Col, Flex, Input, Row, Space, Tooltip } from 'antd';
import { openUrl, translateX } from 'utils/helpers';
import JarvislyEntityForm from 'components/jarvisly-components/forms/Items/JarvislyEntityForm';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import appService from 'services/appService';
import { useSelector } from 'react-redux';
import { JarvislyFormContext } from 'components/jarvisly-components/others/FormComponents';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const PartnerTab = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { selectedEntityPartner, document, entityTypePartner } = props;

  // local variables I ---------------------------------------------------------
  useTabChild('partner', [], props);
  const { selectedSubscription } = useSelector(s => s.authSlice);
  // component states ----------------------------------------------------------
  const [entityType, setEntityType] = useState();
  const [selectedEntity, setSelectedEntity] = useState();
  const [urlPartnerLink, setUrlPartnerLink] = useState(null);

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setEntityType(entityTypePartner);
    setSelectedEntity(selectedEntityPartner);
  }, [entityTypePartner, selectedEntityPartner]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(selectedEntity, selectedEntityPartner);
    if (selectedEntity?._id) {
      setUrlPartnerLink(
        `https://veertical.com/monitoramento/?id=${selectedEntity?._id}&subscriptionId=${selectedSubscription?._id}`,
      );
    } else {
      setUrlPartnerLink(null);
    }
  }, [selectedEntity, selectedSubscription, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onOpenPartnerLink = () => {
    if (!urlPartnerLink?.includes(document?.__partner?._id)) {
      appService.message('w', 'save_before_open_link', 'onClick');
      return;
    }
    openUrl(urlPartnerLink);
  };

  const onCopyPartnerLink = () => {
    if (!urlPartnerLink?.includes(document?.__partner?._id)) {
      appService.message('w', 'save_before_copy_link', 'onClick');
      return;
    }

    appService.copyToClipboard(urlPartnerLink, 'link');
  };

  // local variables III -------------------------------------------------------
  const childProps = {
    ...props,
    entityType,
    setEntityType,
    selectedEntity,
    setSelectedEntity,
  };
  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/*PARTNER CARD */}
      <Card title={<div className="text-muted">{translateX('partner')}</div>}>
        <JarvislyEntityForm
          {...childProps}
          entityPersonType="manual"
          entityPersonDefault="company"
          context="__partner"
          originRegisteredLabel="registered_partner"
          originNewLabel="new_partner"
          searchEntityLabel="search_partner"
          selectable
          additionalInfo="required"
        />
      </Card>
      {/*PARTNER CARD */}

      {/*<Card title={translateX('contacts')}>*/}
      {/*
        <ContactTab {...childProps} />
      */}
      {/*</Card>*/}

      <Card title={translateX('partner_monitoring_link')}>
        <Row>
          <Col span={24}>
            <Flex align="center">
              <Space align="center" style={{ width: '100%' }}>
                <Input
                  // label="partner_link"
                  value={urlPartnerLink}
                  disabled
                />

                <Tooltip title={translateX('open_link')}>
                  <Button
                    type="dashed"
                    icon={<LinkOutlined />}
                    onClick={onOpenPartnerLink}
                    disabled={!urlPartnerLink}
                  />
                </Tooltip>

                <Tooltip title={translateX('copy_link')}>
                  <Button
                    type="dashed"
                    icon={<CopyOutlined />}
                    onClick={onCopyPartnerLink}
                    disabled={!urlPartnerLink}
                  />
                </Tooltip>

                {/*
                <Tooltip title={translateX('send_by_email')}>
                  <Button
                    type="dashed"
                    icon={<SendOutlined />}
                    onClick={onSendPartnerLink}
                    disabled={!urlPartnerLink}
                  />
                </Tooltip>
*/}
              </Space>
            </Flex>
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default PartnerTab;
