import { Tabs } from 'antd';
import { translateWord } from 'utils/helpers';
import { forwardRef, useEffect, useState } from 'react';
import CustomerTab from './customerTab/customerTab';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import useTab from 'components/jarvisly-components/hooks/useTabParentHook';
import entityService from 'services/entityService';
import CrmFollowupTab from './followupTab/CrmFollowupTab';
import { clone } from 'lodash';
import { useSelector } from 'react-redux';
import ParametersProvider from 'components/jarvisly-module/ParametersProvider';

// COMPONENT *******************************************************************
// *****************************************************************************
const CrmForm = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    document,
    selectedModule,
    mode,
    setRequiredFields,
    requiredFields,
  } = props;

  // local variables I ---------------------------------------------------------
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'followup'];
  const { parameters } = useSelector(s => s.moduleSlice);
  const moduleParameters = parameters?.crmParameters || {};

  // component states ----------------------------------------------------------
  const [tab, setTab] = useTab(availableTabs, 'name');
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [entityType, setEntityType] = useState('company');

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    selectedTab: tab,
    initTab,

    selectedEntity,
    setSelectedEntity,
    entityType,
    setEntityType,
    tab,
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const parameterRequiredFields = clone(
      moduleParameters?.entityRequiredFields || [],
    );

    if (Array.isArray(parameterRequiredFields)) {
      if (parameterRequiredFields.includes('cpfCnpj')) {
        parameterRequiredFields.push('cpf', 'cnpj');
      }
      if (parameterRequiredFields.includes('address')) {
        parameterRequiredFields.push('zip', 'number');
      }
    }

    setRequiredFields([
      ...new Set([...parameterRequiredFields, 'name']),
    ]);
  }, [moduleParameters?.entityRequiredFields]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    // create or update entity

    if (!body?.__entity) return;

    const entity = await entityService.persistEntity(
      body,
      '__entity',
      selectedEntity,
      entityType,
      'customer',
    );
    
    if (!entity?._id) return;

    // post or put crm
    const dataSet = {
      ...body,
      entityId: entity?._id,
    };

    // ENTITY
    if (dataSet?.entity) delete dataSet.entity;
    // ATTACHMENTS
    // const requirementAttachs = requirementTabRef?.current?.attachments || [];
    // const proposalAttachs = proposalTabRef?.current?.attachments || [];
    //
    // const attachments = [...requirementAttachs, ...proposalAttachs];
    // dataSet.attachmentIds = appService.parseAttachmentsState(attachments);
    //
    // console.log('ATTACHMENTS ====>', attachments);

    // return false;
    return dataSet;
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <ParametersProvider>
      {/* WRAPPER MODULE PARAMETERS MODAL */}
      {selectedModule?.components?.parameters
        ? selectedModule.components.parameters(childProps)
        : null}
      {/* WRAPPER MODULE PARAMETERS MODAL */}

      <JarvislyFormProvider
        document={document}
        requiredFields={requiredFields}
        setTab={setTab}
        onSave={onSave}
        showSubmitButton={tab !== 'followup'}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </ParametersProvider>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTabItens() {
    return [
      {
        label: translateWord('details'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <CustomerTab {...childProps} thisTab={initTab} context="entity" />
          </JarvislyTabProvider>
        ),
      },
      // {
      //   label: translateWord('requirement'),
      //   key: 'requirement',
      //   hide: false,
      //   disabled: mode === 'add',
      //   children: (
      //     <JarvislyTabProvider tab="requirement">
      //       <CrmRequirementTab
      //         {...childProps}
      //         activeTab="requirement"
      //         ref={requirementTabRef}
      //       />
      //     </JarvislyTabProvider>
      //   ),
      // },
      // {
      //   label: translateWord('proposal'),
      //   key: 'proposal',
      //   hide: false,
      //   disabled: mode === 'add',
      //   children: (
      //     <JarvislyTabProvider tab="proposal">
      //       <CrmProposalTab
      //         {...childProps}
      //         activeTab="proposal"
      //         ref={proposalTabRef}
      //       />
      //     </JarvislyTabProvider>
      //   ),
      // },
      {
        label: translateWord('followup'),
        key: 'followup',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="followup">
            <CrmFollowupTab {...childProps} thisTab="followup" />
          </JarvislyTabProvider>
        ),
      },
    ];
  }
});

export default CrmForm;
