import { Space } from 'antd';
import Search from 'antd/es/input/Search';
import { useContext, useLayoutEffect, useState } from 'react';
import { clone, translateX } from 'utils/helpers';
import { SearchEntity } from 'components/jarvisly-components/others/FormComponents';
import entityService from 'services/entityService';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';

const IotDevicesFilter = props => {
  // providers context ---------------------------------------------------------
  const { textSearch, setTextSearch, drawerSearch, setDrawerSearch } =
    useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------
  const { onFilterDone } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  useLayoutEffect(() => {
    // CUSTOMER
    const customerId = drawerSearch?.customerId;

    if (customerId) {
      (async () => {
        const [entity] = await entityService.getEntitiesByField({
          field: '_id',
          value: customerId,
          projection: ['_id', 'fullName'],
          returnAsObject: true,
        });

        let customer = null;

        if (entity) {
          customer = {
            key: entity?._id, // _id
            label: entity?.fullName, // name
            value: entity?.fullName, // name
          };
        }

        setSelectedCustomer(customer);
      })();
    } else {
      setSelectedCustomer(null);
    }

    // PARTNER
    const partnerId = drawerSearch?.partnerId;

    if (partnerId) {
      (async () => {
        const [entity] = await entityService.getEntitiesByField({
          field: '_id',
          value: partnerId,
          projection: ['_id', 'fullName'],
          returnAsObject: true,
        });

        let partner = null;

        if (entity) {
          partner = {
            key: entity?._id, // _id
            label: entity?.fullName, // name
            value: entity?.fullName, // name
          };
        }

        setSelectedPartner(partner);
      })();
    } else {
      setSelectedPartner(null);
    }
  }, [drawerSearch]);

  // methods -------------------------------------------------------------------
  const onTextSearch = value => {
    setSelectedCustomer(null);
    setSelectedPartner(null);

    const ds = clone(drawerSearch || {});

    delete ds?.customerId;
    delete ds?.partnerId;

    setDrawerSearch({ ...ds });
    onFilterDone(null, { textSearch: value, drawerSearch: ds });
  };

  const onCustomerClear = () => {
    const ds = clone(drawerSearch || {});
    delete ds?.customerId;
    setSelectedCustomer(null);
    onFilterDone(null, { drawerSearch: ds });
  };

  const onCustomerSelect = async value => {
    if (!value?.key) return;

    setTextSearch(null);
    setSelectedCustomer(value);

    onFilterDone(null, {
      drawerSearch: { ...drawerSearch, customerId: value.key },
      textSearch: '',
    });
  };

  const onPartnerClear = () => {
    const ds = clone(drawerSearch || {});
    delete ds?.partnerId;
    setSelectedPartner(null);
    onFilterDone(null, { drawerSearch: ds });
  };

  const onPartnerSelect = async value => {
    if (!value?.key) return;
    setTextSearch(null);
    setSelectedPartner(value);
    onFilterDone(null, {
      drawerSearch: { ...drawerSearch, partnerId: value.key },
      textSearch: '',
    });
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <div className="mt-3">
      <Space direction="vertical">
        <Search
          placeholder={translateX('general_search')}
          allowClear
          enterButton={translateX('search')}
          value={textSearch}
          onChange={({ target: { value } }) => setTextSearch(value)}
          onSearch={onTextSearch}
        />

        <SearchEntity
          x-on-select={onCustomerSelect}
          x-data-profile={['customer']}
          placeholder={translateX('customer')}
          onChange={v => setSelectedCustomer(v)}
          tooltip="Nome, Telefone, Email ou Documento"
          onClear={onCustomerClear}
          value={selectedCustomer}
        />

        <SearchEntity
          x-data-profile={['partner']}
          x-on-select={onPartnerSelect}
          placeholder={translateX('representative')}
          onChange={v => setSelectedPartner(v)}
          tooltip="Nome, Telefone, Email ou Documento"
          onClear={onPartnerClear}
          value={selectedPartner}
        />
      </Space>
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default IotDevicesFilter;
