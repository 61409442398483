import { EditOutlined, HomeOutlined, TeamOutlined } from '@ant-design/icons';
import { LiaFileContractSolid, LiaShippingFastSolid } from 'react-icons/lia';
import { BsBoxSeam } from 'react-icons/bs';
import {
  TbBuildingWarehouse,
  TbForklift,
  TbMoneybag,
  TbSquarePercentage,
  TbUsers,
} from 'react-icons/tb';
import { FaRegHandshake } from 'react-icons/fa6';
import {
  FaBarcode,
  FaChartBar,
  FaRegCalendarCheck,
  FaRegClipboard,
  FaRegEnvelope,
  FaRegIdBadge,
} from 'react-icons/fa';

import {
  HiOutlineBuildingOffice2,
  HiOutlineCalculator,
  HiOutlineDocumentArrowDown,
  HiOutlineDocumentArrowUp,
} from 'react-icons/hi2';
import { LuWarehouse, LuWrench } from 'react-icons/lu';
import {
  MdOutlineAccountBalance,
  MdOutlineEvent,
  MdOutlineFactory,
  MdOutlinePrecisionManufacturing,
  MdOutlineProductionQuantityLimits,
  MdOutlineShoppingCart,
  MdOutlineWarehouse,
  MdShoppingCartCheckout,
} from 'react-icons/md';
import {
  RiCoupon3Line,
  RiDiscountPercentLine,
  RiRefund2Line,
} from 'react-icons/ri';
import {
  IoCalculatorOutline,
  IoDocumentOutline,
  IoDocumentTextOutline,
  IoReceiptOutline,
} from 'react-icons/io5';
import {
  PiHandCoinsLight,
  PiInvoiceLight,
  PiMoney,
  PiTruck,
} from 'react-icons/pi';
import { HiOutlineDocumentSearch } from 'react-icons/hi';
import { FiShoppingBag } from 'react-icons/fi';
import { VscTools } from 'react-icons/vsc';
import { GrMoney } from 'react-icons/gr';

const APP_LEKKUS = {
  ID: 'applekkus',
  NAME: 'Lekkus ERP',
  THEME_COLOR: 'CUSTOM_LEKKUS_APP',
  THEME: 'light',
  FINTECH_SERVICES: true,
  DOMAINS: ['sistema.lekkus.com', 'sistema.lekkus.com.br'],
  '//PROFILE': [
    'singleCompany',
    'multiCompanies',
    'singleClinic',
    'multiClinics',
    'condominium',
  ],
  PROFILE: 'singleCompany',
  DEV_MODE: true,
  COPY_RIGHT: 'Lekkus Technologies',
  '//API_DEV_HOST': ['localhost', 'vps', 'heroku'],
  API_DEV_HOST: 'localhost',
  API_DEV_HTTPS: false,
  API_PORT_DEV: 8000,
  API_PORT_PRODUCTION: 8000,
  WS_PORT: 9001,
  API_HOST_DEV_LOCALHOST: 'localhost',
  API_HOST_DEV_VPS: 'vps.jarvisly.com',
  API_HOST_PRODUCTION: 'vps.jarvisly.com',
  API_HOST_DEV_HEROKU: 'lekkus.herokuapp.com',
  WHATSAPP_INTEGRATED: true,
  TOKEN: process.env.REACT_APP_APPLEKKUS_TOKEN,
  LOCALE: 'pt',

  LOGO_APP: '/apps/applekkus/logo-app.png',
  LOGO_APP_WHITE: '/apps/applekkus/logo-app-white.png',

  LOGO_APP_ICON: '/apps/applekkus/logo-app-icon.png',
  LOGO_APP_ICON_WHITE: '/apps/applekkus/logo-app-icon-white.png',

  LOGO: '/apps/applekkus/logo.png',
  LOGO_WHITE: '/apps/applekkus/logo-white.png',
  LOGO_LOGIN_SIDE: '/apps/applekkus/logo-app-side.png',

  LOGO_SM_WHITE: '/apps/applekkus/logo-sm-white.png',

  TITLE: 'Lekkus ERP',
  '//SLOGAN': 'Condomínios Inteligentes',
  SLOGAN: '',
  SLOGAN2: '',
  SLOGAN_REGISTRATION: 'registration_slogan',
  SLOGAN_REGISTRATION2: '',
  APPLICATION_LAYER: 'adm',
  LOGIN_IDENTIFICATION: 'email',
  SELF_REGISTRATION: true,
  GOOGLE_LOGIN: false,
  FACEBOOK_LOGIN: false,
  RESET_PASSWORD: true,
  NAVIGATION: {
    TYPE: 'MENU',
    CONFIGURATIONS: false,
    LANGUAGES: true,
  },
  ENABLE_MARKETPLACE: false,
  MODULES: [
    'accounts',
    'quotations',
    'customers',
    'receivables',
    'crm',
    'sells',
    'coupons',
    'orders',
    'products',
    'events'
  ],
  MENU_NAVIGATION: [
    // ------------------
    // HOME
    // ------------------
    {
      key: 'home',
      path: `/app/home`,
      title: 'menu',
      icon: HomeOutlined,
      isGroupTitle: true,
      submenu: [
        {
          key: '/app/home/welcome',
          path: `/app/home/welcome`,
          title: 'home',
          className: '',
          icon: HomeOutlined,
          submenu: [],
        },
      ],
    },

    // ------------------
    // RECORDS
    // ------------------
    {
      key: 'records',
      path: '/app/records',
      title: 'records',
      className: '',
      icon: EditOutlined,
      isGroupTitle: false,
      submenu: [
        // entities
        {
          key: 'records_companies',
          path: '/app/records/companies',
          title: 'companies',
          icon: HiOutlineBuildingOffice2,
        },
        {
          key: 'records_employers',
          path: '/app/records/employers',
          title: 'employers',
          icon: FaRegIdBadge,
        },
        {
          key: 'records_providers',
          path: '/app/records/providers',
          title: 'providers',
          icon: FaRegHandshake,
        },
        {
          key: 'records_customers',
          path: '/app/records/customers',
          title: 'customers',
          icon: TeamOutlined,
        },

        // services and products
        {
          key: 'records_services',
          path: '/app/records/services',
          title: 'services',
          icon: LuWrench,
        },
        {
          key: 'records_products',
          path: '/app/records/products',
          title: 'products',
          icon: BsBoxSeam,
        },
        {
          key: 'records_stock',
          path: '/app/records/stock',
          title: 'stock',
          icon: MdOutlineWarehouse,
        },
        {
          key: 'records_fixed_asset',
          path: '/app/records/fixed_asset',
          title: 'fixed_asset',
          icon: FaBarcode,
        },

        // coupons
        {
          key: 'records_coupons',
          path: '/app/records/coupons',
          title: 'discount_coupons',
          icon: RiDiscountPercentLine,
        },

       
      ],
    },

    // ------------------
    // PURCHASES
    // ------------------
    {
      key: 'purchases',
      path: '/app/purchases',
      title: 'purchases',
      className: '',
      icon: MdOutlineShoppingCart,
      isGroupTitle: false,
      submenu: [
        {
          key: 'purchases_requests',
          path: '/app/purchases/requests',
          title: 'requests',
          icon: FaRegEnvelope,
        },
        {
          key: 'purchases_budgets',
          path: '/app/purchases/budgets',
          title: 'budgets',
          icon: IoCalculatorOutline,
        },
        {
          key: 'purchases_orders',
          path: '/app/purchases/orders',
          title: 'orders',
          icon: FaRegCalendarCheck,
        },
        {
          key: 'purchases_invoices',
          path: '/app/purchases/invoices_entrance',
          title: 'invoices_entrance',
          icon: IoReceiptOutline,
        },
      ],
    },

    // ------------------
    // COMMERCIAL
    // ------------------
    {
      key: 'sales',
      path: '/app/commercial',
      title: 'commercial',
      className: '',
      icon: FaRegHandshake,
      isGroupTitle: false,
      submenu: [
        {
          key: 'commercial_crm',
          path: '/app/commercial/crm',
          title: 'crm',
          icon: FaChartBar,
        },
        {
          key: 'commercial_quotations',
          path: '/app/commercial/quotations',
          title: 'quotations',
          icon: IoCalculatorOutline,
        },
         {
          key: 'commercial_events',
          path: '/app/commercial/events',
          title: 'events',
          icon: MdOutlineEvent,
        },
      ],
    },

    // ------------------
    // SALES
    // ------------------
    {
      key: 'sales',
      path: '/app/sales',
      title: 'sales',
      className: '',
      icon: FiShoppingBag,
      isGroupTitle: false,
      submenu: [
        {
          key: 'sales_orders',
          path: '/app/sales/orders',
          title: 'orders',
          icon: FaRegCalendarCheck,
        },
        {
          key: 'sales_checkout',
          path: '/app/sales/checkout',
          title: 'checkout',
          icon: PiMoney,
        },
        {
          key: 'sales_expedition',
          path: '/app/sales/expedition',
          title: 'expedition',
          icon: LiaShippingFastSolid,
        },
        {
          key: 'sales_pos',
          path: '/app/sales/pos',
          title: 'pos',
          icon: MdShoppingCartCheckout,
        },
      ],
    },

    // ------------------
    // TECHNICAL SERVICES
    // ------------------
    {
      key: 'technical',
      path: '/app/technical',
      title: 'technical_area',
      className: '',
      icon: VscTools,
      isGroupTitle: false,
      submenu: [
        {
          key: 'technical_quotations',
          path: '/app/technical/quotations',
          title: 'quotations',
          icon: IoCalculatorOutline,
        },
        {
          key: 'technical_services',
          path: '/app/technical/services',
          title: 'order_service',
          icon: FaRegClipboard,
        },
      ],
    },

    // ------------------
    // INVOICES
    // ------------------
    {
      key: 'invoices',
      path: '/app/invoices',
      title: 'invoicing',
      className: '',
      icon: PiInvoiceLight,
      isGroupTitle: false,
      submenu: [
        {
          key: 'invoices_nfe_emissions',
          path: '/app/invoices/nfe',
          title: 'nfe_emission',
          icon: IoDocumentTextOutline,
        },
        {
          key: 'invoicing_nfse_emissions',
          path: '/app/invoices/nfse',
          title: 'nfse_emission',
          icon: IoDocumentOutline,
        },
        {
          key: 'invoices_store_credit',
          path: '/app/invoices/store_credit',
          title: 'store_credit',
          icon: RiCoupon3Line,
        },
        {
          key: 'invoices_ecf',
          path: '/app/invoices/ecf',
          title: 'ecf',
          icon: HiOutlineDocumentSearch,
        },
        {
          key: 'invoices_cte',
          path: '/app/invoices/cte',
          title: 'cte',
          icon: HiOutlineDocumentArrowUp,
        },
      ],
    },

    // ------------------
    // MANUFACTURING
    // ------------------
    {
      key: 'production',
      path: '/app/production',
      title: 'production',
      className: '',
      icon: MdOutlineFactory,
      isGroupTitle: false,
      submenu: [
        {
          key: 'production_requests',
          path: '/app/production/requests',
          title: 'requests',
          icon: MdOutlineProductionQuantityLimits,
        },
        {
          key: 'production_orders',
          path: '/app/production/orders',
          title: 'production_orders',
          icon: MdOutlineProductionQuantityLimits,
        },
        {
          key: 'production_orders_stock_requests',
          path: '/app/production/stock_requests',
          title: 'stock_request',
          icon: TbBuildingWarehouse,
        },
        {
          key: 'production_assembly',
          path: '/app/production/product_assembly',
          title: 'product_assembly',
          icon: MdOutlinePrecisionManufacturing,
        },
      ],
    },

    // ------------------
    // LOGISTICS
    // ------------------
    {
      key: 'logistics',
      path: '/app/logistics',
      title: 'logistics',
      className: '',
      icon: LuWarehouse,
      isGroupTitle: false,
      submenu: [
        {
          key: 'logistics_separation',
          path: '/app/logistics/separation',
          title: 'separation',
          icon: TbForklift,
        },
        {
          key: 'logistics_packing_list',
          path: '/app/logistics/packing_list',
          title: 'packing_list',
          icon: IoDocumentTextOutline,
        },
        {
          key: 'logistics_shipping',
          path: '/app/logistics/shipping',
          title: 'shipping',
          icon: PiTruck,
        },
        {
          key: 'logistics_devolution',
          path: '/app/logistics/devolution',
          title: 'devolution',
          icon: RiRefund2Line,
        },
      ],
    },

    // ------------------
    // FINANCIAL
    // ------------------
    {
      key: 'financial',
      path: '/app/financial',
      title: 'financial',
      className: '',
      icon: GrMoney,
      isGroupTitle: false,
      submenu: [
        {
          key: 'financial_payable',
          path: '/app/financial/payable',
          title: 'payable',
          icon: PiHandCoinsLight,
        },
        {
          key: 'financial_receivable',
          path: '/app/financial/receivable',
          title: 'receivable',
          icon: TbMoneybag,
        },
        {
          key: 'financial_commissions',
          path: '/app/financial/commissions',
          title: 'commissions',
          icon: TbSquarePercentage,
        },
        {
          key: 'financial_bank_reconciliation',
          path: '/app/financial/bank_reconciliation',
          title: 'bank_reconciliation',
          icon: MdOutlineAccountBalance,
        },
        {
          key: 'financial_contracts',
          path: '/app/financial/contracts',
          title: 'contracts',
          icon: LiaFileContractSolid,
        },
      ],
    },

    // ------------------
    // ACCOUTING
    // ------------------
    {
      key: 'accounting',
      path: '/app/accounting',
      title: 'accounting',
      className: '',
      icon: HiOutlineCalculator,
      isGroupTitle: false,
      submenu: [
        {
          key: 'accounting_purchase_tax',
          path: '/app/accounting/purchase_tax',
          title: 'purchase_tax',
          icon: HiOutlineDocumentArrowUp,
        },
        {
          key: 'accounting_sales_tax',
          path: '/app/accounting/sales_tax',
          title: 'sales_tax',
          icon: HiOutlineDocumentArrowDown,
        },
      ],
    },
  ],
  FIREBASE_CONFIG: JSON.parse(process.env.REACT_APP_APPLEKKUS_FIREBASE_CONFIG),
};

export default APP_LEKKUS;
