import { Button, Flex, Table, Tag, Tooltip } from 'antd';
import { parseDateToDisplay, translateX } from 'utils/helpers';
import { useEffect, useState } from 'react';
import { ReloadOutlined, ThunderboltOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import { useSelector } from 'react-redux';
import appmultiService from 'services/appmultiService';
import Icon from 'components/util-components/Icon';
import { IoHandRightOutline } from 'react-icons/io5';
import { MdOutlinePanTool } from 'react-icons/md';

// COMPONENT *******************************************************************
// *****************************************************************************

const IntegrationLogsTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);

  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const docs = await appmultiService.getApiLogs();

      console.log(docs);

      setData(docs || []);
      setLoading(false);
    })();
  }, []);

  // local variables I ---------------------------------------------------------
  useTabChild('integration', null, props);

  const columns = [
    {
      dataIndex: 'createdAt',
      title: translateX('date'),
      width: 160,
      render: (_, record) => (
        <span>{parseDateToDisplay(record?.createdAt, true, true, true)}</span>
      ),
    },
    {
      dataIndex: 'api',
      title: translateX('api'),
      width: 120,
    },
    {
      dataIndex: 'action',
      title: translateX('action'),
      render: (_, record) => translateX(record?.action),
    },
    {
      dataIndex: 'status',
      title: translateX('status'),
      width: 180,
      render: (_, record) => (
        <Flex justify="start" align="center">
          <Tag
            color={
              record?.status === 'success'
                ? 'green'
                : record?.status === 'failed'
                  ? 'red'
                  : record?.status === 'in_progress'
                    ? 'orange'
                    : 'gray'
            }
            key={record.status}
            className="text-uppercase"
          >
            {translateX(record?.status)}
          </Tag>

          {record?.forced ? (
            <Tooltip title={translateX('manually_forced_api')}>
              <div style={{ marginTop: 6 }}>
                <Icon
                  type={MdOutlinePanTool}
                  className="text-primary font-size-md"
                  style={{ flexShrink: 0 }}
                  tootip
                />
              </div>
            </Tooltip>
          ) : null}
        </Flex>
      ),
    },
    {
      dataIndex: 'description',
      title: translateX('description'),
    },
  ];

  // component states ----------------------------------------------------------
  const [loading, setLoading] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onClickForceApi = async () => {
    const apis = parameters?.apis || [];
    setLoading(false);

    let result;

    for (const item of apis) {
      const { value: api } = item;

      const newData = [
        {
          _id: +new Date(),
          createdAt: dayjs(),
          api,
          status: 'in_progress',
          action: 'exchange_rage',
          description: 'Recuperando câmbio do momento...',
        },
        ...data,
      ];
      setData(newData);
      result = await appmultiService.runApi(api, 'update-exchange');

      newData[0].createdAt = dayjs(result?.[api]?.createdAt);
      newData[0]._id = result?.[api]?._id;
      newData[0].api = result?.[api]?.api;
      newData[0].action = result?.[api]?.action;
      newData[0].status = result?.[api]?.status;
      newData[0].description = result?.[api]?.description;

      setData([...newData]);
    }
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Flex justify="flex-end">
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          loading={loading}
          onClick={onClickForceApi}
        >
          {translateX('force_apis')}
        </Button>
      </Flex>
      <Table
        rowKey="_id"
        columns={columns}
        dataSource={data}
        size="small"
        loading={loading}
      />
    </>
  );
};

export default IntegrationLogsTab;
