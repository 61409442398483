import LiquidFillChart from 'components/jarvisly-components/dashboards/LiquidFillChart/LiquidFillChart';
import { Col, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import React, { useState } from 'react';

const IotDevicesDashboardWaterLevel = props => {
  // props deconstruction ------------------------------------------------------
  const { dataDashboard } = props;

  // local variables -----------------------------------------------------------

  // component states ----------------------------------------------------------
  const [isFlipped, setIsFlipped] = useState({});

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Row gutter={ROW_GUTTER}>
      {dataDashboard?.water_level_sensor?.map(w => (
        <Col key={w._id} xs={24} sm={12} md={6}>
          {/*<Col key={w._id} xs={24} sm={12} md={6} style={{ cursor: 'pointer' }}>*/}
          {/*<ReactCardFlip isFlipped={isFlipped[w._id]}>*/}

          <LiquidFillChart
            record={w}
            onClick={e => {
              e.preventDefault();
              setIsFlipped({ ...isFlipped, [w._id]: true });
            }}
          />

          {/*  <Card*/}
          {/*    title={*/}
          {/*      <div>*/}
          {/*        <div className="font-size-md text-uppercase">{w?.title}</div>*/}
          {/*        <div*/}
          {/*          className="font-size-sm text-muted text-uppercase w-100"*/}
          {/*          style={{ minHeight: 19 }}*/}
          {/*        >*/}
          {/*          {w?.__customer?.name}*/}
          {/*        </div>*/}

          {/*        /!*<div className="font-size-sm text-muted">{item.channel}</div>*!/*/}
          {/*      </div>*/}
          {/*    }*/}
          {/*    style={{*/}
          {/*      height: 316, // Define a altura fixa do card*/}
          {/*      overflow: 'hidden', // Limita o conteúdo visível na área do card*/}
          {/*    }}*/}
          {/*    onClick={e => {*/}
          {/*      e.preventDefault();*/}
          {/*      setIsFlipped({ ...isFlipped, [w._id]: false });*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <div*/}
          {/*      style={{ height: '100%', overflowY: 'auto', paddingRight: 10 }}*/}
          {/*    >*/}
          {/*      <Descriptions*/}
          {/*        bordered*/}
          {/*        layout="vertical"*/}
          {/*        size="small"*/}
          {/*        items={[*/}
          {/*          {*/}
          {/*            key: `a`,*/}
          {/*            label: 'Quantidade',*/}
          {/*            children: <>33.345 Litros</>,*/}
          {/*          },*/}
          {/*          {*/}
          {/*            key: 'b',*/}
          {/*            label: 'Capacidade',*/}
          {/*            children: <>50.000 Litros</>,*/}
          {/*          },*/}
          {/*          {*/}
          {/*            key: `c`,*/}
          {/*            label: 'Quantidade2',*/}
          {/*            children: <>133.345 Litros</>,*/}
          {/*          },*/}
          {/*          {*/}
          {/*            key: 'd',*/}
          {/*            label: 'Capacidade2',*/}
          {/*            children: <>150.000 Litros</>,*/}
          {/*          },*/}
          {/*        ]}*/}
          {/*        className="w-100 mb-4"*/}
          {/*        column={1}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </Card>*/}
          {/*</ReactCardFlip>*/}
        </Col>
      ))}
    </Row>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default IotDevicesDashboardWaterLevel;
