import { useEffect } from 'react';
import { Card, Col, Row } from 'antd';
import {
  JarvislyAsyncAutoComplete,
  JarvislyDatePicker,
  JarvislyInput,
  JarvislyNumber, JarvislyTitle
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { translateX } from 'utils/helpers';
import financialService from 'services/financialService';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const CouponsRecordIndex = props => {
  // const { documentContext } = useModuleContext();
  // const { listPlans } = documentContext;
  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const valueOrPercentageOptions = financialService.valueOrPercentageOptions();

  // component states ----------------------------------------------------------
  // const [, setOperator] = useState(null);
  // const [, setFilteredData] = useState([]);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {}, []);
  // methods -------------------------------------------------------------------
  // const onChangeOperator = value => {
  //   const list = appmultiService.sorterPlans(listPlans, {
  //     api: value,
  //   });

  //   setFilteredData(list);
  //   setOperator(value);
  // };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <Card title={translateX('product')}>
      <JarvislyTitle title="products" />
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} md={4}>
          <JarvislyInput name={'name'} label={'code'} />
        </Col>
        <Col xs={24} md={4}>
          <JarvislyNumber
            name="discount"
            label="discount_value"
            min={1}
            max={100}
            addonBefore={null}
            decimalSeparator=","
            suffixDefaultValue="R$"
            addonAfter={valueOrPercentageOptions}
            suffixStyle={{ width: 60 }}
          />
        </Col>
        <Col xs={24} md={6}>
          <JarvislyInput name={'qty'} label="quantity" />
        </Col>
        <Col xs={24} md={6}>
          <JarvislyDatePicker name={'expirationDate'} label="expiration_date" />
        </Col>
        <Col xs={24} md={4}>
        <JarvislyAsyncAutoComplete
          name="selectedOperator"
          label="operator"
          x-data-type="company"
          x-data-profile="operator"
        />
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} md={24}>
          <JarvislyInput name={'description'} />
        </Col>
      </Row>
    </Card>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default CouponsRecordIndex;
