import { Button, Card, Col, Flex, Input, Row, Space, Tooltip } from 'antd';
import { openUrl, translateX } from 'utils/helpers';
import JarvislyEntityForm from 'components/jarvisly-components/forms/Items/JarvislyEntityForm';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import appService from 'services/appService';
import { useSelector } from 'react-redux';
import { JarvislyFormContext } from 'components/jarvisly-components/others/FormComponents';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const IotDevicesCustomerTab = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    document,
    entityTypeCustomer,
    selectedEntityCustomer,
  } = props;

  // local variables I ---------------------------------------------------------
  useTabChild('customer', ['__customer', 'selectedEntity'], props);
  const { selectedSubscription } = useSelector(s => s.authSlice);

  // component states ----------------------------------------------------------
  const [entityType, setEntityType] = useState();
  const [selectedEntity, setSelectedEntity] = useState();
  const [urlCustomerLink, setUrlCustomerLink] = useState(null);

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setEntityType(entityTypeCustomer);
    setSelectedEntity(selectedEntityCustomer);
  }, [entityTypeCustomer, selectedEntityCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(selectedEntity)

    if (selectedEntity?._id) {
      setUrlCustomerLink(
        `https://veertical.com/monitoramento/?id=${selectedEntity?._id}&subscriptionId=${selectedSubscription?._id}`,
      );
    } else {
      setUrlCustomerLink(null);
    }
  }, [selectedEntity, selectedSubscription, form]); // eslint-disable-line react-hooks/exhaustive-deps


  // methods -------------------------------------------------------------------
  const onOpenCustomerLink = () => {
    if (!urlCustomerLink?.includes(document?.__customer?._id)) {
      appService.message('w', 'save_before_open_link', 'onClick');
      return;
    }
    openUrl(urlCustomerLink);
  };

  const onCopyCustomerLink = () => {
    if (!urlCustomerLink?.includes(document?.__customer?._id)) {
      appService.message('w', 'save_before_copy_link', 'onClick');
      return;
    }

    appService.copyToClipboard(urlCustomerLink, 'link');
  };

  /*
    const onSendCustomerLink = () => {
      console.log('link enviado por email...', urlCustomerLink);
    };
  */

  // local variables III -------------------------------------------------------
  const childProps = {
    ...props,
    entityType,
    setEntityType,
    selectedEntity,
    setSelectedEntity,
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/*CUSTOMER CARD */}
      <Card title={<div className="text-muted">{translateX('customer')}</div>}>
        <JarvislyEntityForm
          {...childProps}
          entityPersonType="manual"
          entityPersonDefault="company"
          context="__customer"
          originRegisteredLabel="registered_customer"
          originNewLabel="new_customer"
          searchEntityLabel="search_customer"
          selectable
          additionalInfo="required"
        />
      </Card>
      {/*CUSTOMER CARD */}

      {/*<Card title={translateX('contacts')}>*/}
      {/*
        <ContactTab {...childProps} />
      */}
      {/*</Card>*/}

      <Card title={translateX('customer_monitoring_link')}>
        <Row>
          <Col span={24}>
            <Flex align="center">
              <Space align="center" style={{ width: '100%' }}>
                <Input
                  // label="customer_link"
                  value={urlCustomerLink}
                  disabled
                />

                <Tooltip title={translateX('open_link')}>
                  <Button
                    type="dashed"
                    icon={<LinkOutlined />}
                    onClick={onOpenCustomerLink}
                    disabled={!urlCustomerLink}
                  />
                </Tooltip>

                <Tooltip title={translateX('copy_link')}>
                  <Button
                    type="dashed"
                    icon={<CopyOutlined />}
                    onClick={onCopyCustomerLink}
                    disabled={!urlCustomerLink}
                  />
                </Tooltip>

                {/*
                <Tooltip title={translateX('send_by_email')}>
                  <Button
                    type="dashed"
                    icon={<SendOutlined />}
                    onClick={onSendCustomerLink}
                    disabled={!urlCustomerLink}
                  />
                </Tooltip>
*/}
              </Space>
            </Flex>
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default IotDevicesCustomerTab;
