import { translateX } from 'utils/helpers';
import { Card, Flex, Table } from 'antd';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import {
  FormButton,
  JarvislyEditViewRemoveItem,
} from 'components/jarvisly-components/others/FormComponents';
import { PlusOutlined } from '@ant-design/icons';
import { capitalize } from 'lodash';
import axiosService from 'services/axiosService';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';

const GuestTab = props => {
  const {
    isWorking,
    setIsWorking,
    document,
    mode,
    setShowGuestModal,
    selectedRecord,
    setSelectedRecord,
    refreshDocument,
  } = props;

  // local variables -----------------------------------------------------------
  useTabChild('guest', ['name'], props);

  const isDisabled = isWorking || mode === 'view';
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onAdd = () => {
    setSelectedRecord(null);
    setShowGuestModal(true);
  };

  const onEdit = record => {
    setSelectedRecord(record);
    console.log('selectedRecord', selectedRecord);
    setShowGuestModal(true);
  };

  const onDelete = async record => {
    setIsWorking(true);
    console.log(record);
    if (record?._id) {
      if (
        record.dataProfile.length === 1 &&
        record.dataProfile.includes('associate')
      ) {
        await axiosService
          .delete({
            url: '/v1/entity',
            _id: record._id,
            headers: { 'x-forcepermanentlydeletation': true },
          })
          .then();
      }

      const axiosOptions = {
        url: `/v1/events/add-to-set/${document?._id}/$pull/associateId`,
        data: { _id: record?._id },
      };
      await axiosService.put(axiosOptions);
    }

    refreshDocument();
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* Debugging: Output the dependents data */}
      <Card>
        <Table
          rowKey={record => record._id} // Assuming each dependent has a unique _id
          columns={tableColumns.filter(x => !x.hidden)}
          dataSource={document?.__entity}
          pagination={false}
          loading={!document || isWorking}
        />

        <Flex justify="center">
          <FormButton
            title="add_associate"
            className="m-3"
            style={{ width: 200 }}
            icon={<PlusOutlined />}
            disabled={isDisabled}
            onClick={onAdd}
          />
        </Flex>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTableColumns() {
    return [
      {
        title: translateX('name'),
        dataIndex: 'name',
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                shape="circle"
                title={capitalize(record?.name)}
                subtitle={translateX(record?.gender)}
                foreColor={record?.foreColor}
                backColor={record?.backColor}
              />
            </div>
          );
        },
      },
      {
        title: translateX('phone'),
        dataIndex: 'phone',
        render: (_, record) => {
          return (
            <>
              <div>{record?.phone}</div>
            </>
          );
        },
      },
      {
        title: translateX('cpf'),
        dataIndex: 'cpf',
        render: (_, record) => {
          return (
            <>
              <div>{record?.cpf}</div>
            </>
          );
        },
      },

      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onEdit}
              onDelete={onDelete}
              record={record}
              recordId={record?.entityId}
              isDisabled={isDisabled}
              deleteTitle={translateX(
                'remove_this_item',
                ['%ITEM%'],
                ['contact'],
              )}
            />
          );
        },
      },
    ].filter(x => !x.hidden);
  }
};

export default GuestTab;
