import { Tabs } from 'antd';
import { translateWord } from 'utils/helpers';
import { forwardRef, useRef } from 'react';
import QuotationsIndex from './Quotations/QuotationsIndex';
import { JarvislyFormProvider, JarvislyTabProvider } from 'components/jarvisly-components/others/FormComponents';
import JarvislyDocumentStatusModal from '../../../../../components/jarvisly-components/forms/modals/JarvislyDocumentStatusModal';
import CrmFollowupTab from 'modules/app-views/applekkus/crm/Form/followupTab/CrmFollowupTab';
import useTabParent from 'components/jarvisly-components/hooks/useTabParentHook';

// COMPONENT *******************************************************************
// *****************************************************************************
const MultiLeadsForm = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, mode } = props;

  // local variables I ---------------------------------------------------------
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'followup'];
  const documentStatusModalRef = useRef(null);
  
  // component states ----------------------------------------------------------
  const [tab, setTab] = useTabParent(availableTabs, 'name');

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    activeTab: tab,
    initTab,
  }
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  // useEffect(() => {
  //   if (!availableTabs.includes(tab)) setTab(initTab);
  //   url.updateUrlQueryParams({ tab }, true);
  //   setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'name' }));
  // }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  // useImperativeHandle(ref, () => ({
  //   documentStatusModalRef,
  // }));

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  // UI COMPONENT --------------------------------------------------------------

  return (
    <>
      {/* DOCUMENT STATUS MODAL WRAPPER */}
      <JarvislyDocumentStatusModal {...props} ref={documentStatusModalRef} />
      {/* DOCUMENT STATUS MODAL WRAPPER */}

      <JarvislyFormProvider
        name="form-leads"
        document={document}
        autoFocus="name"
        requiredFields={[]}
        showSubmitButton={false}
        setTab={setTab}
        // onSave={onSave}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateWord('online_quotations'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <QuotationsIndex {...props} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateWord('followup'),
        key: 'followup',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="followup">
            <CrmFollowupTab {...childProps} activeTab="followup" />
          </JarvislyTabProvider>
        ),
      },
    ];
  }
});

export default MultiLeadsForm;
