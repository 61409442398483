import { Col, Row, Skeleton, Tag, Tooltip } from 'antd';
import { Icon } from 'components/util-components/Icon';
import {
  MailOutlined, PhoneOutlined
} from '@ant-design/icons';
import {
  parsePhoneToDisplay, translateX
} from 'utils/helpers';
import dayjs from 'dayjs';
import Flex from 'components/shared-components/Flex';
import { LiaTemperatureLowSolid } from 'react-icons/lia';
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import {
  PiClockClockwiseBold,
  PiClockCounterClockwiseBold,
} from 'react-icons/pi';
import { LuTimerOff } from 'react-icons/lu';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
export const CrmFormHeaderTitle = props => {
  const { document } = props;

  return (
    <Tooltip title={translateX('opportunity_number')}>
      <span className="text-uppercase">
        {/*{document?._metadata?.__documentCode}*/}
        <span>
          {translateX('opportunity')} Nº {document?._metadata?.counter}
        </span>
      </span>
    </Tooltip>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const CrmFormHeaderSubtitle = props => {
  const { document } = props;

  return (
    <Tooltip title={translateX('company')}>
      <span>{document?.__entity?.name}</span>
    </Tooltip>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const CrmFormHeaderTags = props => {
  return <CrmStatusTag {...props} />;
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const CrmFormHeaderColumn1 = props => {
  const { document } = props;

  return (
    <Col xs={24} sm={8}>
      <Row style={{ display: document?.__entity?.email ? 'initial' : 'none' }}>
        <Col>
          <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
            <Tooltip placement="right" title={translateX('email')}>
              <Flex
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                className="text-truncate"
              >
                <Icon
                  type={MailOutlined}
                  className="text-primary font-size-md"
                  style={{ flexShrink: 0 }}
                />
                <div className="ml-2 font-weight-semibold" style={{ flex: 1 }}>
                  {document?.__entity?.email
                    ? document?.__entity?.email
                    : translateX('n_a')}
                </div>
              </Flex>
            </Tooltip>
          </Row>
        </Col>
      </Row>

      <Row style={{ display: document?.__entity?.phone ? 'initial' : 'none' }}>
        <Col>
          <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
            <Tooltip placement="right" title={translateX('phone')}>
              <Flex
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                className="text-truncate"
              >
                <Icon
                  type={PhoneOutlined}
                  className="text-primary font-size-md"
                  style={{ flexShrink: 0 }}
                />
                <div className="ml-2 font-weight-semibold" style={{ flex: 1 }}>
                  {document?.__entity?.phone
                    ? parsePhoneToDisplay(document?.__entity?.phone)
                    : translateX('n_a')}
                </div>
              </Flex>
            </Tooltip>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const CrmFormHeaderColumn2 = props => {
  const { document, isWorking } = props;
  return (
    <Col xs={24} sm={8}>
      {/* TEMPERATURE */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        {/* Aqui fazemos a busca pelo ID correspondente */}
        {document?.temperatureId &&
          document?.lkq_crmParameters?.temperatures &&
          (() => {
            const temperature = document.lkq_crmParameters.temperatures.find(
              temp => temp._id === document.temperatureId,
            );

            return temperature ? (
              <Row
                style={{
                  display: temperature ? 'initial' : 'none',
                }}
              >
                <Col>
                  <Row className="p-1" style={{ minHeight: 29 }}>
                    <Tooltip
                      placement="right"
                      title={translateX('temperature')}
                    >
                      <Flex
                        flexDirection="row"
                        justifyContent="start"
                        alignItems="center"
                        className="text-truncate"
                      >
                        <Icon
                          type={LiaTemperatureLowSolid}
                          className="text-primary font-size-md"
                          style={{ flexShrink: 0 }}
                        />
                        <span className="ml-2 font-weight-semibold">
                          {temperature.label}
                        </span>
                      </Flex>
                    </Tooltip>
                  </Row>
                </Col>
              </Row>
            ) : null;
          })()}
      </Skeleton>
      {/* TEMPERATURE */}

      {/* BOARD */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        {/* Aqui fazemos a busca pelo ID correspondente */}
        {document?.boardId &&
          document?.lkq_crmParameters?.boards &&
          (() => {
            const board = document.lkq_crmParameters.boards.find(
              temp => temp._id === document.boardId,
            );

            return board ? (
              <Row
                style={{
                  display: board ? 'initial' : 'none',
                }}
              >
                <Col>
                  <Row className="p-1" style={{ minHeight: 29 }}>
                    <Tooltip placement="right" title={translateX('board')}>
                      <Flex
                        flexDirection="row"
                        justifyContent="start"
                        alignItems="center"
                        className="text-truncate"
                      >
                        <Icon
                          type={MdOutlineDashboardCustomize}
                          className="text-primary font-size-md"
                          style={{ flexShrink: 0 }}
                        />
                        <span className="ml-2 font-weight-semibold">
                          {board.label}
                        </span>
                      </Flex>
                    </Tooltip>
                  </Row>
                </Col>
              </Row>
            ) : null;
          })()}
      </Skeleton>
      {/* BOARD */}

      {/* PIPELINE */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        {/* Aqui fazemos a busca pelo ID correspondente */}
        {document?.pipelineId &&
          document?.lkq_crmParameters?.pipelines &&
          (() => {
            const pipeline = document.lkq_crmParameters.pipelines.find(
              temp => temp._id === document.pipelineId,
            );

            return pipeline ? (
              <Row
                style={{
                  display: pipeline ? 'initial' : 'none',
                }}
              >
                <Col>
                  <Row className="p-1" style={{ minHeight: 29 }}>
                    <Tooltip placement="right" title={translateX('pipeline')}>
                      <Flex
                        flexDirection="row"
                        justifyContent="start"
                        alignItems="center"
                        className="text-truncate"
                      >
                        <Icon
                          type={HiOutlineSwitchHorizontal}
                          className="text-primary font-size-md"
                          style={{ flexShrink: 0 }}
                        />
                        <span className="ml-2 font-weight-semibold">
                          {pipeline.label}
                        </span>
                      </Flex>
                    </Tooltip>
                  </Row>
                </Col>
              </Row>
            ) : null;
          })()}
      </Skeleton>
      {/* PIPELINE */}
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const CrmFormHeaderColumn3 = props => {
  const { document, isWorking } = props;

  return (
    <Col xs={24} sm={8}>
      {/* LAST INTERACTION */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?.lastInteraction ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?.lastInteraction && (
                <Tooltip
                  placement="right"
                  title={translateX('last_interaction')}
                >
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={PiClockCounterClockwiseBold}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {document?.lastInteraction &&
                        dayjs(document?.lastInteraction).format('L')}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* LAST INTERACTION */}

      {/* NEXT INTERACTION */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?.nextInteraction ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?.nextInteraction && (
                <Tooltip
                  placement="right"
                  title={translateX('next_interaction')}
                >
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={PiClockClockwiseBold}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {document?.nextInteraction &&
                        dayjs(document?.nextInteraction).format('L')}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* NEXT INTERACTION */}

      {/* DEADLINE */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?.deadline ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?.deadline && (
                <Tooltip placement="right" title={translateX('deadline')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={LuTimerOff}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {document?.deadline &&
                        dayjs(document?.deadline).format('L')}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* DEADLINE */}

    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const CrmStatusTag = props => {
  const record = props?.record || props?.document;
  const dse = props?.status || record?.status; // document status in english
  const documentStatus = dse && translateX(dse);

  const overdue =
    !props?.status &&
    record?.nextInteraction &&
    dayjs(record._metadata.audit.createdAt).diff(dayjs(), 'days') > 1;

  return (
    <>
      {documentStatus && (
        <Tag
          style={{ margin: 4 }}
          color={getCrmtatusColor(overdue ? 'overdue' : dse)}
        >
          {documentStatus}
        </Tag>
      )}
    </>
  );
};

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================
export const getCrmtatusColor = status => {
  switch (status) {
    case 'lost':
      return 'red';

    case 'waiting_for_payment':
      return 'orange';

    case 'overdue':
    case 'late_payment':
      return '#f50';

    case 'waiting_for_voucher':
    case 'paid':
    case 'received':
    case 'proposal':
    case 'negotiation_1':
    case 'negotiation_2':
      return 'purple';

    case 'qualification_1':
    case 'qualification_2':
    case 'meeting':
      return 'blue';

    case 'done':
    case 'contract_closed':
      return 'green';

    case 'first_contact':
      return 'yellow';

    case 'cancelled':
    case 'cancelled_by_customer':
    case 'backlog':
      return 'gray';

    default:
      return 'geekblue';
  }
};
