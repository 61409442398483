import { createContext, useContext, useEffect, useState } from 'react';
import {
  rdxSetApiRequest, rdxSetModuleFreeze,
  rdxSetParameters,
  rdxSetShowMyStates
} from 'store/slices/moduleSlice';
import {
  buildUrlQueryString,
  parseUrlQueryString,
} from 'components/jarvisly-module/module-methods';
import { clone, isMongoObjectId } from 'utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import moduleService from './ModuleService';

// EXPORT CONTEXT **************************************************************
// *****************************************************************************

let lastAction = null;
export const ModuleContext = createContext({});

export const useModuleContext = () => {
  return useContext(ModuleContext);
};

// PROVIDER ********************************************************************
// *****************************************************************************

export const ModuleProvider = ({ children, setDone }) => {
  // local variables -----------------------------------------------------------
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    listState,
    apiRequest,
    selectedModule,
    showMyStates,
    documentState,
    parameters,
    loadingModule,
  } = useSelector(s => s.moduleSlice);

  const { selectedSubscription } = useSelector(s => s.authSlice);

  // component states ----------------------------------------------------------

  // general states
  // const [isRequesting, setIsRequesting] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  // list states
  const [response, setResponse] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [dataKanban, setDataKanban] = useState({});
  const [dataGrid, setDataGrid] = useState([]);
  const [dataDashboard, setDataDashboard] = useState([]);
  // const [settings, setSettings] = useState(null);
  const [pagination, setPagination] = useState(false);
  const [searchText, setSearchText] = useState({});
  const [sortOrder, setSortOrder] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [moduleRefreshed, setModuleRefreshed] = useState(+new Date());
  const [moduleFocus, setModuleFocus] = useState({ field: null, tab: null });
  const [formWorking, setFormWorking] = useState(false);
  const [moduleForm, setModuleForm] = useState(null);
  const [hasDrawerFilter, setHasDrawerFilter] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const [drawerSearch, setDrawerSearch] = useState({});

  // document states
  const [formState, setFormState] = useState({
    hasPicture: true,
    disablePicture: false,
    mode: 'add',
    sectionSettingsComponent: null,
    isLoaded: false,
    activeTab: selectedModule?.documentState?.initTab, //searchParams.get('tab'),
    data: {},
    attachments: [],
    valueBlock: null,
  });

  const [document, setDocument] = useState(null);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    return () => {
      resetContext();
    };
  }, [selectedModule?.name]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showMyStates) showStatesOnConsole();
  }, [showMyStates]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (documentState?.autoFill && documentState?.mockDocument) {
      setDocument(documentState?.mockDocument);
    }
  }, [documentState?.mockDocument]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if (selectedSubscription?._id && selectedModule?.components?.parameters) {
    if (selectedSubscription?._id) {
      // load parameters
      (async () => await refreshParameters())();
    }
  }, [selectedSubscription?._id, selectedModule?.name]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // text search in drawer
    const tsid = selectedModule?.listState?.textSearchInDrawer;

    // has drawer filter
    const hdf = !!(
      (drawerSearch && Object.keys(drawerSearch).length > 0) ||
      (tsid && textSearch)
    );

    setHasDrawerFilter(hdf);
  }, [textSearch, drawerSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const refreshModule = () => setModuleRefreshed(+new Date());

  const resetContext = () => {
    // dispatch(rdxSetParameters(null));

    resetListContext();
    resetDocumentContext();
  };

  const duplicateDocument = () => {
    lastAction = 'duplicated';

    delete document._id;
    delete document._metadata;

    const initialState = selectedModule?.documentState?.initialState || {};
    setDocument({ ...document, ...initialState });
    setDocumentContext({ mode: 'add', isLoaded: false });
    setTimeout(() => {
      lastAction = null;
    }, 1000);
  };

  const resetListContext = () => {
    setDataList([]);
    setDataKanban({});
    setDataGrid([]);
    setDataDashboard([]);
    setPagination(false);
    setSearchText({});
    setSortOrder({});
    setShowFilter(false);
    setResponse(null);
    setShowSettings(false);
  };

  const resetDocumentContext = (forceIsLoadedTrue = false) => {
    if (lastAction === 'duplicated') return;

    setFormState({
      hasPicture: true,
      disablePicture: false,
      mode: 'add',
      sectionSettingsComponent: null,
      isLoaded: forceIsLoadedTrue,
      activeTab: null, //selectedModule?.documentState?.initTab,
      data: {},
      attachments: [],
      valueBlock: null,
    });

    if (documentState?.autoFill && documentState?.mockDocument) {
      setDocument(documentState?.mockDocument);
    } else {
      setDocument(null);
    }

    dispatch(rdxSetShowMyStates(false));
    setResponse(null);
    setModuleRefreshed(+new Date());
  };

  const setDocumentContext = (obj = {}) => {
    setFormState({ ...formState, ...obj });
  };

  const refreshDocument = async (_id, noDisable, freeze) => {
    if (!_id) _id = document?._id;

    try {
      if (isMongoObjectId(_id)) {
        return await fetchDocument(_id, noDisable, freeze);
      }
    } catch (error) {}
  };

  const refreshList = async (dataFrom, tab) => {
    if (dataFrom?.search && Object.keys(dataFrom.search).length > 0) {
      dataFrom.textSearch = {};
    }

    try {
      if (selectedModule?.url) {
        if (selectedModule?.components?.kanban) {
          fetchKanban(dataFrom, tab).then();
        }
        if (selectedModule?.components?.grid) {
          fetchGrid(dataFrom, tab).then();
        }

        if (selectedModule?.components?.dashboard) {
          fetchDashboard(dataFrom, tab).then();
        }

        return await fetchList(dataFrom, tab);
      }
    } catch (error) {}
  };

  const refreshDataOfList = async (dataFrom, tab) => {
    if (dataFrom?.search && Object.keys(dataFrom.search).length > 0) {
      dataFrom.textSearch = {};
    }

    try {
      if (selectedModule?.url) {
        return await fetchList(dataFrom, tab, false);
      }
    } catch (error) {}
  };

  const refreshDashboard = async (dataFrom, tab) => {
    if (dataFrom?.search && Object.keys(dataFrom.search).length > 0) {
      dataFrom.textSearch = {};
    }

    try {
      return await fetchDashboard(dataFrom, tab);
    } catch (error) {}
  };

  const refreshParameters = async noShowLoading => {
    try {
      if (selectedModule?.name) {
        return await fetchParameters(noShowLoading);
      }
    } catch (error) {}
  };

  const showStatesOnConsole = () => {
    console.log('*********************');
    console.log('*** MODULE CONTEXT **');
    console.log('*********************');
    console.log('isRequesting:', isRequesting);
    console.log('parameters:', parameters);
    console.log('listState:', listState);
    console.log('dataList:', dataList);
    console.log('dataKanban:', dataKanban);
    console.log('dataGrid:', dataGrid);
    console.log('dataDashboard:', dataDashboard);
    console.log('pagination:', pagination);
    console.log('searchText:', searchText);
    console.log('sortOrder:', sortOrder);
    console.log('response:', response);
    console.log('document:', document);
    console.log('documentContext (formState):', formState);
    console.log('lastAction:', lastAction);
    console.log('');
  };

  // PROVIDER CONTEXT ----------------------------------------------------------

  const exposedValues = {
    // general states
    setIsWorking: loading => setIsRequesting(!loadingModule ? loading : false),
    isWorking: isRequesting,

    // settings,
    refreshParameters,
    parameters,

    // list states
    refreshList,
    refreshDataOfList,
    setDataList,
    setDataKanban,
    setDataGrid,
    dataList,
    dataKanban,
    dataGrid,
    pagination,
    searchText,
    sortOrder,
    setSearchText,
    showFilter,
    setShowFilter,
    showSettings,
    setShowSettings,

    // dashboard
    refreshDashboard,
    setDataDashboard,
    dataDashboard,

    // document states
    refreshDocument,
    setDocument,
    document,
    documentContext: formState,
    _id: document?._id || null,
    // documentContext: setFormState,
    //formState,
    //setFormState,

    resetContext,

    resetDocumentContext,

    resetListContext,
    setDocumentContext,

    selectedSubscription,
    subscriptionId: selectedSubscription?._id,
    selectedModule,

    duplicateDocument,

    // form,
    // setForm,
    mode: formState?.mode,

    moduleRefreshed,
    refreshModule,
    setModuleFocus,
    moduleFocus,
    lastAction,

    formWorking,
    setFormWorking,

    moduleForm,
    setModuleForm,

    hasDrawerFilter,
    setHasDrawerFilter,

    textSearch,
    setTextSearch,
    drawerSearch,
    setDrawerSearch,
  };

  return (
    <ModuleContext.Provider value={exposedValues}>
      {children}
    </ModuleContext.Provider>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  async function fetchDocument(_id, noDisable, freeze = true) {
    setIsRequesting(!loadingModule ? freeze : false);
    dispatch(rdxSetModuleFreeze(freeze));

    try {
      const [doc, request] = await moduleService.getPagination(_id);

      if (selectedModule?.methods?.decorateDocument && doc) {
        selectedModule.methods.decorateDocument(doc);
      }

      setDocument(doc);
      setResponse(request);
      const mode = selectedModule.documentState.disableLockForEdit
        ? 'edit'
        : 'view';
      setDocumentContext({ mode: noDisable ? 'edit' : mode, isLoaded: true });

      // setDone(true);
      moduleService.loadingModule(false);

      setTimeout(() => {
        setIsRequesting(false);
        dispatch(rdxSetModuleFreeze(false));
      }, 200);

      return doc;
    } catch (error) {
      moduleService.loadingModule(false);
      setIsRequesting(false);
      dispatch(rdxSetModuleFreeze(false));
      throw error;
    }
  }

  async function fetchList(dataFrom, tab, useLoading = true) {
    if (useLoading) {
      setIsRequesting(!loadingModule);
    }

    if (!dataFrom) setHasDrawerFilter(false);
    dispatch(rdxSetModuleFreeze(true));

    try {
      const { qsObj, qsStr } = buildQueryString(dataFrom, tab);
      const [docs, response] = await moduleService.getPagination(null, qsStr);
      const list = docs?.list || docs;

      if (
        selectedModule?.methods?.decorateDocument &&
        list &&
        list?.length > 0
      ) {
        for (const d of list) selectedModule?.methods?.decorateDocument(d);
      }

      setDataList(list);
      // setDataDashboard(dashboard);

      setResponse(response);
      setPagination({
        size: 'small',
        current: response?.summary?.pagination?.currentPage || 1,
        pageSize: response?.summary?.pagination?.pageSize,
        total: response?.summary?.pagination?.records || 0,
      });
      dispatch(rdxSetModuleFreeze(false));

      // COMENTADO POIS QUANDO DAVA O RELOAD ESTANDO NO DASHBOARD ELE VOLTAVA PARA LIST
      /*
            dispatch(
              rdxSetListState({
                ...listState,
                isTableChanged: validateTableChanges(qsObj),
                // isFilterChanged: selectedModule?.initialRequest?.folder !== qsObj?.folder,
                isFilterChanged:
                  selectedModule?.initialRequest?.folder !== qsObj?.folder ||
                  JSON.stringify(selectedModule?.initialRequest?.textSearch) !==
                    JSON.stringify(qsObj?.textSearch) ||
                  selectedModule?.initialRequest?.moreFilters !== qsObj?.moreFilters,
              }),
            );
      */

      dispatch(
        rdxSetApiRequest({
          ...apiRequest,
          currentQs: { ...qsObj },
        }),
      );

      // setDone(true);
      moduleService.loadingModule(false);
      setIsRequesting(false);
      return list;
    } catch (error) {
      moduleService.loadingModule(false);
      setIsRequesting(false);
      throw error;
    }
  }

  async function fetchKanban(dataFrom, tab) {
    setIsRequesting(!loadingModule);
    dispatch(rdxSetModuleFreeze(true));

    try {
      const { qsObj, qsStr } = buildQueryString(dataFrom, tab);
      const [doc, response] = await moduleService.getKanban(null, qsStr);

      // if (
      //   selectedModule?.methods?.decorateDocument &&
      //   docs &&
      //   docs?.length > 0
      // ) {
      //   for (const d of docs) selectedModule.methods.decorateDocument(d);
      // }

      setDataKanban(doc);
      setResponse(response);
      dispatch(rdxSetModuleFreeze(false));

      dispatch(
        rdxSetApiRequest({
          ...apiRequest,
          currentQs: { ...qsObj },
        }),
      );

      setIsRequesting(false);
      return doc;
    } catch (error) {
      setIsRequesting(false);
      throw error;
    }
  }

  async function fetchGrid(dataFrom, tab) {
    setIsRequesting(!loadingModule);
    dispatch(rdxSetModuleFreeze(true));

    try {
      const { qsObj, qsStr } = buildQueryString(dataFrom, tab);
      const [doc, response] = await moduleService.getGrid(null, qsStr);

      // if (
      //   selectedModule?.methods?.decorateDocument &&
      //   docs &&
      //   docs?.length > 0
      // ) {
      //   for (const d of docs) selectedModule.methods.decorateDocument(d);
      // }

      setDataGrid(doc);
      setResponse(response);
      dispatch(rdxSetModuleFreeze(false));

      dispatch(
        rdxSetApiRequest({
          ...apiRequest,
          currentQs: { ...qsObj },
        }),
      );

      setIsRequesting(false);
      return doc;
    } catch (error) {
      setIsRequesting(false);
      throw error;
    }
  }

  async function fetchDashboard(dataFrom, tab) {
    try {
      const { qsStr } = buildQueryString(dataFrom, tab);
      const [doc] = await moduleService.getDashboard(null, qsStr);

      setDataDashboard(doc);
      return doc;
    } catch (error) {
      throw error;
    }
  }

  async function fetchParameters(noShowLoading) {
    if (!noShowLoading) {
      setIsRequesting(!loadingModule);
      dispatch(rdxSetModuleFreeze(true));
    }

    try {
      const [doc] = await moduleService.getParameters();

      if (selectedModule?.methods?.decorateParameters && doc) {
        selectedModule.methods.decorateParameters(doc);
      }

      dispatch(rdxSetParameters(doc));

      setIsRequesting(false);
      return doc;
    } catch (error) {
      setIsRequesting(false);
      throw error;
    }
  }

  function buildQueryString(dataFrom, tab) {
    const initialQs = apiRequest?.initialQs;
    const currentQs = apiRequest?.currentQs;

    let qsObj, qsStr, viewMode;

    switch (dataFrom) {
      case 'url': // from url refresh
        qsObj =
          parseUrlQueryString(location?.search?.replace('?', '')) ||
          clone(initialQs);
        break;

      case 'memo': // from return of document to list keeping the last request
        qsObj = clone(currentQs);
        break;

      default:
        if (typeof dataFrom === 'object') {
          // from table changes by user
          qsObj = currentQs
            ? clone({
                ...currentQs,
                ...dataFrom,
              })
            : clone(dataFrom);
        } else {
          // from SideNav menu click
          qsObj = clone(initialQs);
        }
    }

    const vmArr = Array.isArray(selectedModule?.listState?.viewMode)
      ? selectedModule?.listState?.viewMode
      : [selectedModule?.listState?.viewMode];

    const urlViewMode = qsObj?.viewMode?.toLowerCase();
    viewMode = !vmArr.includes(urlViewMode) ? 'list' : urlViewMode;
    qsObj.viewMode = viewMode;

    if (qsObj && Object.keys(qsObj)?.length > 0) {
      // compare properties with initial (accepted by current module)
      Object.entries(qsObj).map(([k, v]) => {
        // if (!initialQs?.[k]) {
        //   delete qsObj[k];
        // } else {
        // folder validation
        if (
          k === 'folder' &&
          (!qsObj[k] || !initialQs[`${k}Options`]?.includes(v))
        )
          qsObj[k] = initialQs[k];

        // filter validation
        if (k === 'filter') {
          if (typeof qsObj[k] !== 'object') {
            delete qsObj[k];
          } else {
            Object.entries(qsObj[k]).map(([fKey, fValue]) => {
              if (!initialQs[k][fKey] || !Array.isArray(qsObj[k][fKey])) {
                delete qsObj[k][fKey];
              } else {
                const parsedArr = qsObj[k][fKey].filter(x =>
                  initialQs[`${k}Options`][fKey].includes(x),
                );

                if (parsedArr.length === 0) {
                  qsObj[k][fKey] = initialQs[k][fKey];
                } else {
                  qsObj[k][fKey] = parsedArr;
                }
              }

              return [fKey, fValue];
            });
          }
        }

        // pagination validation
        if (k === 'pagination') {
          if (typeof qsObj[k] !== 'object') {
            delete qsObj[k];
          } else {
            Object.entries(qsObj[k]).map(([fKey, fValue]) => {
              if (fKey === 'pageNumber') {
                if (
                  isNaN(fValue) ||
                  Number(fValue) < 0 ||
                  Number(fValue) > 90000
                ) {
                  qsObj[k][fKey] = initialQs[k][fKey];
                } else {
                  qsObj[k][fKey] = parseInt(fValue);
                }
              }

              if (fKey === 'pageSize') {
                let pageSize = Number(fValue);

                if (!initialQs[`${k}Options`][fKey]?.includes(pageSize)) {
                  pageSize = initialQs[`${k}Options`][fKey][0];
                  qsObj[k][fKey] = pageSize;
                  localStorage.setItem('pageSize', pageSize.toString());
                } else {
                  qsObj[k][fKey] = pageSize;
                }
              }

              return [fKey, fValue];
            });
          }
        }

        // sorter validation
        if (k === 'sorter') {
          if (typeof qsObj[k] !== 'object') {
            delete qsObj[k];
          } else {
            Object.entries(qsObj[k]).map(([fKey, fValue]) => {
              if (!qsObj[k][fKey] || !initialQs[`${k}Options`]?.includes(fKey))
                delete qsObj[k][fKey];
              return [fKey, fValue];
            });
          }
        }
        // }

        return [k, v];
      });

      // added missing properties
      initialQs &&
        Object.entries(initialQs)?.map(([k, v]) => {
          if (!qsObj[k]) {
            qsObj[k] = initialQs[k];
          } else {
            // filter and pagination validation
            if (['filter', 'pagination'].includes(k)) {
              Object.entries(initialQs[k]).map(([fKey, fValue]) => {
                if (!qsObj[k][fKey]) qsObj[k][fKey] = initialQs[k][fKey];
                return [fKey, fValue];
              });
            }
          }

          return [k, v];
        });

      if (Object.keys(qsObj).length > 0) {
        // --------------------------------------------------------
        // reorder sorter precedence
        // --------------------------------------------------------
        const sorterObj = {};
        const sorterForTableOptions = {};

        selectedModule.initialRequest.sorterOptions.forEach(s => {
          if (qsObj.sorter?.[s]) {
            sorterObj[s] = qsObj.sorter[s];
            sorterForTableOptions[s] = [1, '1'].includes(qsObj.sorter?.[s])
              ? 'ascend'
              : 'descend';
          }
        });

        setSortOrder(sorterForTableOptions);
        qsObj.sorter = sorterObj;
        // --------------------------------------------------------

        // --------------------------------------------------------
        // search and filter
        // --------------------------------------------------------
        setSearchText({ ...qsObj?.search });
        // --------------------------------------------------------

        // delete all querystring options
        delete qsObj.folderOptions;
        delete qsObj.filterOptions;
        delete qsObj.paginationOptions;
        delete qsObj.sorterOptions;

        if (qsObj && !qsObj?.tab) {
          qsObj.tab = tab;
        }

        const vmArr = Array.isArray(selectedModule?.listState?.viewMode)
          ? selectedModule.listState.viewMode
          : [selectedModule.listState.viewMode];

        if (!vmArr.includes(viewMode)) viewMode = vmArr[0];
        if (qsObj && !qsObj?.viewMode) qsObj.viewMode = viewMode;

        qsStr = buildUrlQueryString(qsObj);
        navigate(`?${qsStr}`);
      }
    }

    const ts = typeof qsObj?.textSearch !== 'object' ? qsObj.textSearch : '';

    setTextSearch(ts);
    setDrawerSearch(qsObj?.drawerSearch || {});

    return {
      qsObj,
      qsStr,
    };
  }

  // function validateTableChanges(currentQs) {
  //   const obj1 = {
  //     filter: apiRequest?.initialQs?.filter,
  //     folder: apiRequest?.initialQs?.folder,
  //     pagination: apiRequest?.initialQs?.pagination,
  //     search: apiRequest?.initialQs?.search,
  //     sorter: apiRequest?.initialQs?.sorter,
  //   };

  //   const obj2 = {
  //     filter: currentQs?.filter,
  //     folder: currentQs?.folder,
  //     pagination: currentQs?.pagination,
  //     search: currentQs?.search,
  //     sorter: currentQs?.sorter,
  //   };

  //   return !isObjEquals(obj1, obj2);
  // }
};
