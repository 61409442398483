import { TagOutlined } from '@ant-design/icons';
import { Card, Col, Modal, Row } from 'antd';
import {
  JarvislyCnpj,
  JarvislyEmail,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyPhone,
  JarvislyRadio,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useContext, useEffect, useState } from 'react';
import appService from 'services/appService';
import axiosService from 'services/axiosService';
import { translateX } from 'utils/helpers';

// ...

const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showPixEditModal,
    setShowPixEditModal,
    isDisabled,
    setPixType,
    setFormatterRecord,
    pixType,
    formattedRecord,

    pixArr,
    selectedPix,
  } = props;

  console.log('modal edit pix =>', pixArr, selectedPix);

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view')
    : !selectedPix?._id
      ? translateX('new_pix_key')
      : translateX('edit_pix_key');

  // component states -----------------------------------------------------------
  const [selectedType, setSelectedType] = useState();

  useEffect(() => {
    if (selectedPix && selectedPix._id) {
      setSelectedType(selectedPix.type);
      setPixType(selectedPix.type);
      console.log('Modo de edição - setSelectedPix contém:', selectedPix);
      console.log('PixType no modo de edição:', pixType);

      setFormatterRecord({
        pixArr: {
          mobile: selectedPix.mobile,
          email: selectedPix.email,
          cnpj: selectedPix.cnpj,
          _id: selectedPix._id,
        },
      });

      console.log('formattedRecord no modo de edição:', formattedRecord);
      form.setFieldsValue(formattedRecord);
    } else {
      // Modo de adição: limpa os campos e redefine os estados
      console.log(
        'Modo de adição - setSelectedPix não contém dados',
        selectedPix,
      );
      console.log('PixType no modo de adição:', pixType);

      setSelectedType(null);
      setPixType(null);
      setFormatterRecord({
        pixArr: {
          mobile: '',
          email: '',
          cnpj: '',
          _id: null,
        },
      });

      form.resetFields();
    }
  }, [showPixEditModal, selectedPix, form]);

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowPixEditModal(false);
  };

  const handleTypeChange = e => {
    setSelectedType(e.target.value);
    setPixType(e.target.value);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Modal
      title={
        <>
          <TagOutlined />
          <span className="ml-2">{title}</span>
        </>
      }
      destroyOnClose={true}
      open={showPixEditModal}
      width={640}
      okText={translateX('save')}
      onCancel={onClose}
      cancelText={translateX('close')}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={() => (isDisabled ? onClose() : form.submit())}
      getContainer={false}
      okType={'primary'}
    >
      <Card title={translateX('choice_an_option')}>
        <Row gutter={ROW_GUTTER} style={{ marginBottom: '24px' }}>
          <Col xs={24} sm={24}>
            <JarvislyRadio
              name="type"
              onChange={handleTypeChange}
              value={selectedType}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              direction="horizontal"
              options={[
                { value: 'cnpj', text: translateX('cnpj') },
                { value: 'mobile', text: translateX('mobile') },
                { value: 'email', text: translateX('email') },
              ]}
            />
          </Col>
        </Row>

        <Row gutter={ROW_GUTTER}>
          {selectedType === 'cnpj' && (
            <Col xs={24} sm={24}>
              <JarvislyCnpj label="cnpj" name={['pixArr', 'cnpj']} required />
            </Col>
          )}
          {selectedType === 'mobile' && (
            <Col xs={24} sm={24}>
              <JarvislyPhone
                label="mobile"
                name={['pixArr', 'mobile']}
                required
              />
            </Col>
          )}
          {selectedType === 'email' && (
            <Col xs={24} sm={24}>
              <JarvislyEmail
                label="email"
                name={['pixArr', 'email']}
                required
              />
            </Col>
          )}
        </Row>
      </Card>
    </Modal>
  );
};

// MAIN COMPONENT **************************************************************

const PixEditModal = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    setShowPixEditModal,
    selectedRecord,
    selectedPix,
    refreshDocument,
    setSelectedPix,
  } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [pixType, setPixType] = useState();
  const [formattedRecord, setFormatterRecord] = useState();

  // local variables II --------------------------------------------------------

  const childProps = {
    ...props,
    pixType,
    setPixType,
    formattedRecord,
    setFormatterRecord,
  };

  // providers context ---------------------------------------------------------

  // methods -------------------------------------------------------------------

  const onConfirm = async body => {
    const pixArr = [];

    // (!selectedPix?._id) = NOVO
    // ja existe (para nao duplicar)
    // findindex no pixarr com o dado de input (tudo em lower)
    // setPixArr(p=>([...p, body]))

    // = EDIT
    // const idx = pixarr.findIndex(_id === selectedpix._id)
    // pixArr[idx] = body
    // setPixArr([...pixArr])

    if (!selectedPix && !pixType) {
      appService.notification('i', '', '', 'select_type_pix_key', 5);
      return;
    }

    const newBody = {
      ...body?.body?.pixArr,
      type: pixType,
    };

    // Verifica se é uma nova chave ou uma atualização
    if (selectedPix?._id) {
      const existingIndex = pixArr.findIndex(
        key => key._id === selectedPix._id,
      );

      if (existingIndex !== -1) {
        // Atualiza item existente
        selectedRecord.pixArr[existingIndex] = {
          ...selectedRecord.pixArr[existingIndex],
          ...newBody,
        };
      }
    } else {
      // Adiciona novo item
      selectedRecord.pixArr = [...(selectedRecord.pixArr || []), newBody];
    }

    const axiosOptions = {
      url: `/v1/banks`,
      _id: selectedRecord._id,
      data: {
        pixArr: selectedRecord.pixArr,
      },
    };

    // Realiza a requisição e aguarda o retorno
    const response = await axiosService.put(axiosOptions);

    if (response?.data) {
      // Atualiza o selectedPix com o novo item (assumindo que o backend retorna o novo _id)
      const newPixArr = response.data.pixArr.find(
        key =>
          key.cnpj === newBody.cnpj ||
          key.mobile === newBody.mobile ||
          key.email === newBody.email,
      );
      setSelectedPix(newPixArr);
    }

    appService.message('s', 'saved_parameters', 'onSave');
    refreshDocument(formattedRecord);
    setShowPixEditModal(false);
  };

  // variables III -------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      document={formattedRecord}
      requiredFields={['pixAdd.Email', 'pixAdd.mobile', 'pixAdd.cnpj']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      autoFocus={false}
      abortComponentSave
    >
      <ModalComponent {...childProps} />
    </JarvislyFormProvider>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default PixEditModal;
