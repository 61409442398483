import { useEffect } from 'react';
import { Badge, Tabs } from 'antd';
import { translateX } from 'utils/helpers';
import {
  ApiOutlined,
  AppstoreAddOutlined,
  CreditCardOutlined,
  DatabaseOutlined,
  TeamOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import app from 'configs/ConfigIndex';
import AccountOrganizationsList from './organizationsTab/List/ListIndex';
import appService from 'services/appService';
import AccountWhatsAppList from 'modules/app-views/shared-modules/account/whatsappTab/List/ListIndex';
import moduleService from 'components/jarvisly-module/ModuleService';
import ProfileTab from './profileTab/ProfileTab';
import IntegrationLogsTab from './integrationLogsTab/IntegrationLogsTab';
import useTabParent from '../../../../components/jarvisly-components/hooks/useTabParentHook';
import { rdxSetParameters } from '../../../../store/slices/moduleSlice';
import { useDispatch } from 'react-redux';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const privateTabs = ['team', 'billings', 'integration'];
if (app.ENABLE_MARKETPLACE) privateTabs.push('resources');
const publicTabs = ['profile', 'organizations'];

// COMPONENT *******************************************************************
// *****************************************************************************

const AccountIndex = props => {
  // props deconstruction ------------------------------------------------------
  let { selectedSubscription, selectedModule } = props;

  // local variables -----------------------------------------------------------
  const initTab = selectedModule?.documentState?.initTab;
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const url = useUrl();

  // component states ----------------------------------------------------------
  const [tab, setTab] = useTabParent([...privateTabs, ...publicTabs], 'name');
  // const [activeTab, setActiveTab] = useState();

  // local variables II --------------------------------------------------------
  // const [searchParams] = useSearchParams();

  // -3: carrinho e demo (vencido), 1: carrinho, 3: carrinho e demo (vigente)
  const shopCartCounter = selectedSubscription?.marketplace?.filter(m =>
    [-3, 1, 3].includes(m.status),
  ).length;
  // const userProfile = selectedSubscription?.profile || 'guest';

  const childProps = {
    ...props,
    activeTab: tab,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    // let tab = searchParams.get('tab');

    appService.initRoute({
      moduleType: null,
      showPageHeader: false,
      showBreadcrumb: false,
      disableSubscriptionCombo: false,
    });

    (async () => {
      const [doc] = await moduleService.getParameters(true);

      if (selectedModule?.methods?.decorateParameters && doc) {
        selectedModule.methods.decorateParameters(doc);
      }

      dispatch(rdxSetParameters(doc));
    })();

    moduleService.loadingModule(false);
  }, [selectedSubscription]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  // local variables III -------------------------------------------------------
  const tabItems = buildTabItems();

  // UI COMPONENT --------------------------------------------------------------
  return (
    <div className="card-container">
      <Tabs
        type="line"
        tabPosition="top"
        activeKey={tab}
        items={tabItems.filter(x => !x.hide)}
        onTabClick={onTabClick}
      />
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItems() {
    return [
      {
        key: 'profile',
        hide: false,
        label: (
          <>
            <UserOutlined />
            <span>{translateX('my_profile')}</span>
          </>
        ),
        // children: (<AccountProfile/>),
        // children: <h1>PROFILE</h1>,
        children: <ProfileTab {...childProps} />,
      },

      {
        key: 'organizations',
        hide: false,
        label: (
          <>
            <DatabaseOutlined />
            <span>{translateX(app.PROFILE.TITLE_ORGANIZATIONS_TAP)}</span>
          </>
        ),
        children: <AccountOrganizationsList {...childProps} />,
        // children: <div>Organizations List</div>,
        // children: <ListIndex {...props} />,
      },

      {
        key: 'resources',
        hide:
          selectedSubscription?.profile !== 'owner' || !app.ENABLE_MARKETPLACE,
        label: (
          <>
            <AppstoreAddOutlined />
            <span>{translateX('resources')}</span>
          </>
        ),
        // children: (<AccountMarketplace/>),
        children: <div>Resources</div>,
      },

      {
        key: 'team',
        hide: selectedSubscription?.profile !== 'owner',
        label: (
          <>
            <TeamOutlined />
            <span>{translateX('team')}</span>
          </>
        ),
        children: <div>Team</div>,
        // children: <AccountTeams />,
      },

      {
        key: 'billings',
        hide: selectedSubscription?.profile !== 'owner',
        label: (
          <Badge count={shopCartCounter}>
            <CreditCardOutlined />
            <span className="mr-2">{translateX('billings')}</span>
          </Badge>
        ),
        children: <div>Billing</div>,
        // children: <AccountBillings />,
      },

      {
        key: 'whatsapp',
        hide: !app.WHATSAPP_INTEGRATED || !selectedSubscription?._id,
        label: (
          <>
            <WhatsAppOutlined />
            <span>{translateX('whatsapp')}</span>
          </>
        ),
        children: <AccountWhatsAppList {...childProps} />,
      },

      {
        key: 'integration',
        // hide: !app.SHOW_INTEGRATION_LOGS || !selectedSubscription?._id,
        label: (
          <>
            <ApiOutlined />
            <span>{translateX('integration')}</span>
          </>
        ),
        children: <IntegrationLogsTab {...childProps} />,
      },
    ];
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default AccountIndex;
