import React from 'react';
import { Flex, Table, Tag, Tooltip } from 'antd';
import { setLocale, translateWord, translateX } from 'utils/helpers';
import {
  FormButton,
  JarvislyEditViewRemoveItem,
} from 'components/jarvisly-components/others/FormComponents';
import appService from 'services/appService';
import { ApiTwoTone, BankOutlined, PlusOutlined } from '@ant-design/icons';
import { API_BANKS } from 'constants/ApiConstant';
import axiosService from 'services/axiosService';
import { TbCoinBitcoin } from 'react-icons/tb';
import Icon from '../../../util-components/Icon';
import authService from '../../../../services/authService';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const JarvislyBanksIndex = props => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    setIsWorking,
    document,
    mode,
    setShowBankAddModal,
    setShowBankEditModal,

    refreshDocument,
    selectedModule,

    setSelectedRecord
  } = props;

  // local variables -----------------------------------------------------------
  const isMobile = appService.isMobile();
  const isDisabled = isWorking || mode === 'view';
  const tableColumns = buildTableColumns();
  const data = document?.__banks?.length > 0 ? document?.__banks : null;

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onAdd = () => {
    setSelectedRecord(null);
    setShowBankAddModal(true);
  };

  const onEdit = record => {
    setSelectedRecord(record);
    setShowBankEditModal(true);
  };

  const onDelete = async record => {
    setIsWorking(true);
    const axiosOptions = {
      url: API_BANKS,
      _id: record._id,
    };
    await axiosService.delete(axiosOptions);
    refreshDocument(null, true);
    if (selectedModule.name === 'accounts') authService.refreshAccount();
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Table
        rowKey="_id"
        className="w-100"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={data || []}
        pagination={false}
        loading={!document || isWorking}
      />

      <Flex justify={isMobile || !data ? 'center' : 'end'}>
        <FormButton
          title="add"
          className="m-3"
          icon={<PlusOutlined />}
          disabled={isDisabled}
          onClick={onAdd}
        />
      </Flex>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns() {
    return [
      {
        title: setLocale('banks'),
        dataIndex: 'bankTitle',
        className: 'text-truncate',
        hidden: isMobile,
        ellipsis: true, // Ativa o truncamento automático do Antd
        render: (_, record) => record.bankTitle,
      },

      {
        title: translateWord('api'),
        dataIndex: 'asaasInfo',
        className: 'text-truncate',
        width: 60,
        align: 'center',
        hidden: isMobile,
        render: (_, record) => (
          <div className="text-center">
            {record?.apiIntegrated && (
              <Tooltip title={translateWord('api_integrated')}>
                <div>
                  <Icon
                    type={ApiTwoTone}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        ),
      },

      {
        title: setLocale('alias'),
        dataIndex: 'alias',
        className: 'text-truncate',
        width: 120,
        render: (_, record) => {
          return <strong>{record.alias}</strong>;
        },
      },

      {
        title: setLocale('agency'),
        dataIndex: 'agency',
        className: 'text-truncate',
        hidden: isMobile,
        width: 100,
        render: (_, record) => {
          return (
            <div>
              {record.agency}
              {record.agencyDigit ? <span>-{record.agencyDigit}</span> : null}
            </div>
          );
        },
      },

      {
        title: setLocale('account'),
        dataIndex: 'account',
        className: 'text-truncate',
        width: 120,
        render: (_, record) => {
          return (
            <div>
              <Tooltip
                title={translateX(
                  record.accountType === 'digital'
                    ? 'digital_account'
                    : 'conventional_account',
                )}
              >
                {record.account}
                {record.accountDigit ? (
                  <span>-{record.accountDigit}</span>
                ) : null}
                &nbsp;&nbsp;
                {record.accountType === 'digital' ? (
                  <Icon
                    type={TbCoinBitcoin}
                    className="text-primary font-size-md icon-mt-4"
                  />
                ) : (
                  <BankOutlined />
                )}
              </Tooltip>
            </div>
          );
        },
      },

      {
        title: setLocale('status'),
        dataIndex: 'status',
        className: 'text-truncate',
        hidden: isMobile,
        render: (_, record) => {
          return (
            <div>
              {record.status === 'approved' ? (
                <Tag
                  className="text-capitalize"
                  style={{ margin: 4 }}
                  color="green"
                >
                  {translateX('active_account')}
                </Tag>
              ) : (
                <Tag
                  className="text-capitalize"
                  style={{ margin: 4 }}
                  color="gray"
                >
                  {translateX('waiting_for_activation')}
                </Tag>
              )}
            </div>
          );
        },
      },

      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onEdit}
              onDelete={onDelete}
              record={record}
              recordId={record?._id}
              isDisabled={isDisabled}
              deleteTitle={translateX('remove_this_item', ['%ITEM%'], ['bank'])}
            />
          );
        },
      },
    ].filter(x => !x.hidden);
  }
};

export default JarvislyBanksIndex;
