import { Table } from 'antd';
import { translateX } from 'utils/helpers';
import React from 'react';

const UseList = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    // columnSorter,
    // columnSearch,
    // columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    showNumberOfLine,
  } = props;

  // local variables I ---------------------------------------------------------
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTableColumns() {
    return [
      {
        hidden: !showNumberOfLine,
        title: translateX('line'),
        width: 80,
        render: (item, record, index) => {
          if (!pagination) return;

          const { current, pageSize } = pagination;

          return (
            <div className="text-left">
              {current * pageSize - pageSize + index + 1 || index}
            </div>
          );
        },
      },

      {
        title: translateX('date'),
        dataIndex: 'name',
        width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {translateX(record?.name)}
          </span>
        ),
      },

      {
        title: translateX('order'),
        dataIndex: 'discount',
        width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {translateX(record?.discount)}
          </span>
        ),
      },

      {
        title: translateX('client'),
        dataIndex: 'quantity',
        width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {translateX(record?.qty)}
          </span>
        ),
      },

      {
        title: translateX('value'),
        dataIndex: 'expiration_date',
        width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {translateX(record?.expiration_date)}
          </span>
        ),
      },

      {
        title: translateX('dicount_with_value'),
        dataIndex: 'last_update',
        width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {translateX(record?.last_update)}
          </span>
        ),
      },
    ].filter(x => !x.hidden);
  }
};

export default UseList;
