import { useLocation, useNavigate } from 'react-router-dom';

const useUrl = props => {
  const navigate = useNavigate();
  const location = useLocation();

  const getUrl = () => {
    return location.pathname + location.search;
  };

  const getUrlQueryParams = (stringFormat = false) => {
    const queryString = new URLSearchParams(location.search);

    if (stringFormat) return queryString;

    const queryParams = {};
    for (const [key, value] of queryString.entries()) {
      queryParams[key] = value;
    }

    return queryParams;
  };

  /*
    const updateUrlQueryParams = (queryStringObj, replace = false) => {
      const currentQueryParams = getUrlQueryParams();
      const queryParams = { ...currentQueryParams, ...queryStringObj };
      const queryString = new URLSearchParams(queryParams).toString();

      navigate({ search: `?${queryString}`, replace });
    };
  */

  // const updateQueryString = (key, value) => {
  const updateUrlQueryParams = (queryStringObj, replace = false) => {
    if (
      typeof queryStringObj !== 'object' ||
      Object?.keys(queryStringObj).length === 0
    ) {
      return;
    }

    const searchParams = new URLSearchParams(location.search);

    // Atualiza ou adiciona o parâmetro específico
    Object.keys(queryStringObj).forEach(key => {
      searchParams.set(key, queryStringObj[key]);
    });

    const newUrl = {
      pathname: location.pathname,
      search: `?${searchParams.toString()}`,
    };

    // Navega para a nova URL com a query string atualizada
    navigate(newUrl, { replace }); // Replace evita adicionar ao histórico
  };

  return {
    getUrl,
    getUrlQueryParams,
    updateUrlQueryParams,
  };
};

export default useUrl;
