import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Timeline } from 'antd';
import { translateX } from 'utils/helpers';
import appService from 'services/appService';
import { useSelector } from 'react-redux';
import axiosService from 'services/axiosService';
import {
  JarvislyFormContext, JarvislyTextArea
} from 'components/jarvisly-components/others/FormComponents';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';

// COMPONENT *******************************************************************
// *****************************************************************************
const CrmFollowupTab = React.memo(props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { isWorking, tab, _id, document, selectedModule } = props;
  // local variables I ---------------------------------------------------------
  useTabChild('followup', [], props);
  const isMobile = appService.isMobile();
  const followupRef = useRef(null);
  const user = useSelector(s => s.authSlice.selectedUser);
  
  // component states ----------------------------------------------------------
  const [comment, setComment] = useState('');
  const [reminderDate, setReminderDate] = useState('');
  // const [reminder, setReminder] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setTimeout(() => followupRef?.current?.focus(), 200);
  }, [tab]);

  // methods -------------------------------------------------------------------
  const onAddFollowup = async () => {
    form.submit();
    const axiosOptions = {
      url: `${selectedModule.api}/add-to-set/${_id}/$push/followUp`,
      data: {
        accountId: user._id,
        text: comment,
        reminderDate,
      },
    };
    await axiosService.put(axiosOptions);
    // refreshDocument(_id, true);
    setComment('');
    setReminderDate('');
    followupRef?.current?.focus();
  };

  // const isDisabledButton = () {
  //   if(comment === "" || comment === null) {
  //     setDisabledButton(false)
  //   }
  // }
  

  // local variables III -------------------------------------------------------
  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Row justify="center">
        <Col
          span={24}
          style={{
            padding: isMobile ? '0' : '0 20%',
          }}
          className="text-center"
        >
          <JarvislyTextArea
            ref={followupRef}
            rows={2}
            label="comment"
            disabled={false}
            value={comment}
            required
            onChange={({ target: { value } }) => setComment(value)}
          />
        </Col>
      </Row>
      <Row justify="end" className="pb-5 pt-3">
        <Col
          span={24}
          style={{
            padding: isMobile ? '0' : '0 20%',
          }}
          className="text-right"
        >
          <Button
            type="primary"
            onClick={onAddFollowup}
            // disabled={isDisabled}
            loading={isWorking}
          >
            <span>{translateX('save')}</span>
          </Button>
        </Col>
      </Row>
      {/* <Row
        justify="start"
        align="start"
        gutter={ROW_GUTTER}
        style={{
          padding: isMobile ? '0' : '0 20%',
        }}
      >
        <Col align="start">
          <JarvislySwitch
            label="activate_reminder"
            checked={reminder}
            onChange={setReminder}
            disabled={false}
          />
        </Col>
      </Row> */}
      {/* <Row
        justify="start"
        align="start"
        gutter={ROW_GUTTER}
        style={{
          padding: isMobile ? '0' : '0 20%',
        }}
      >
        <Col xs={18} sm={6}>
          {reminder && (
            <JarvislyDatePicker
              name="reminderDate"
              label="reminder_date"
              value={reminderDate}
              onChange={date => setReminderDate(date)}
              disabledDate={disabledDueDate}
              disabled={false}
            />
          )}
        </Col>
      </Row> */}

      {/* <Row justify="center" style={{
            flexDirection: 'column',
          }}>
        <Col
          style={{
            padding: isMobile ? '0' : '0 20%',
          }}
        > */}
      <Timeline mode="left" items={document?.followUp || []} />
      {/* </Col>
      </Row> */}
    </>
  );
  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
});
export default CrmFollowupTab;
