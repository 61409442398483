import { Tabs } from 'antd';
import { returnOnlyNumbers, translateWord } from 'utils/helpers';
import {
  forwardRef, useRef,
  useState
} from 'react';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import JarvislyDocumentStatusModal from 'components/jarvisly-components/forms/modals/JarvislyDocumentStatusModal';
import { useSelector } from 'react-redux';
import useTabParent from 'components/jarvisly-components/hooks/useTabParentHook';
import EventsRecordIndex from './Record/RecordIndex';
import GuestTab from './Guest/GuestTab';
import GuestModal from './modals/GuestModal';
import entityService from 'services/entityService';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const EventsForm = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, setRequiredFields, requiredFields, mode } = props;

  // local variables I ---------------------------------------------------------
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'guest'];
  const documentStatusModalRef = useRef(null);
  const { parameters } = useSelector(s => s.moduleSlice);

  // component states ----------------------------------------------------------
  const [tab, setTab] = useTabParent(availableTabs, 'name');
  const [formData, setFormData] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [entityType, setEntityType] = useState('company');
  const [showGuestModal, setShowGuestModal] = useState();

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    activeTab: tab,
    initTab,

    selectedEntity,
    setSelectedEntity,
    entityType,
    setEntityType,

    formData,

    showGuestModal, setShowGuestModal,
    setSelectedRecord, selectedRecord
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  // useEffect(() => {
  //   if (!availableTabs.includes(tab)) setTab(initTab);
  //   url.updateUrlQueryParams({ tab }, true);
  //   setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'cpf' }));
  // }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps


  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;

    return body;
  };

  // UI COMPONENT --------------------------------------------------------------
  return (


    <>

      {/* DOCUMENT STATUS MODAL WRAPPER */}
      <GuestModal
        {...childProps}
      />
      {/* DOCUMENT STATUS MODAL WRAPPER */}

      {/* DOCUMENT STATUS MODAL WRAPPER */}
      <JarvislyDocumentStatusModal
        {...childProps}
        ref={documentStatusModalRef}
      />
      {/* DOCUMENT STATUS MODAL WRAPPER */}

      <JarvislyFormProvider
        document={document}
        requiredFields={requiredFields}
        setTab={setTab}
        onSave={onSave}
        // onValuesChange={(v, allValues) => setFormData({ ...allValues })}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateWord('events'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <EventsRecordIndex {...childProps} />
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateWord('inviteds'),
        key: 'guest',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab={'guest'}>
            <GuestTab {...childProps} activeTab={tab}/>
          </JarvislyTabProvider>
        ),
      },
    ];
  }
});

export default EventsForm;
