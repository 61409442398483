import {
  JarvislyFormContext,
  JarvislyFormProvider,
} from '../../../others/FormComponents';
import {
  forwardRef,
  useContext,
  useEffect
} from 'react';
import { returnOnlyNumbers, translateWord, translateX } from 'utils/helpers';
import { Alert, Modal, Spin, Tag, Tooltip } from 'antd';
import { ApiTwoTone, BankOutlined } from '@ant-design/icons';
import JarvislyBankExistingAccountForm from '../../Items/JarvislyBankExistingAccountForm';
import { API_BANKS } from 'constants/ApiConstant';
import axiosService from 'services/axiosService';
import appService from 'services/appService';
import Icon from '../../../../util-components/Icon';
import dayjs from 'dayjs';
import bankService from 'services/bankService';
import authService from 'services/authService';
import PixEditModal from 'components/jarvisly-components/forms/Banks/modal/PixEditModal';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    record,
    showBankEditModal,
    setShowBankEditModal,
    afterClose,
    isDisabled,
    updating,
    isWorking,
  } = props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled ? translateX('view') : translateX('edit');
  const isMobile = appService.isMobile();
  const createdAccountDate = dayjs(record?.asaasInfo?.createdAt).format('L LT');
  const infoFrom = ['%EMAIL%', '%DATE%', '%EMAIL_ASAAS%'];
  const infoTo = [
    record?.asaasInfo?.loginEmail,
    `${createdAccountDate}h`,
    'nao-responda@asaas.com.br',
  ];

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form?.resetFields();
    if (record) form?.setFieldsValue(record);
  }, [record, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowBankEditModal(false);
  };

  const onAfterClose = () => {
    afterClose();
    form?.resetFields();
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className={`title-fixed-with-18 ${
          isMobile ? 'center-modal-buttons' : ''
        }`}
        title={
          <>
            <BankOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showBankEditModal}
        width={640}
        onCancel={onClose}
        getContainer={false}
        confirmLoading={updating}
        afterClose={onAfterClose}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        okButtonProps={{ style: { width: 120 } }}
        okType={isDisabled ? 'default' : 'primary'}
        okText={translateX(isDisabled ? 'close' : 'save')}
        cancelButtonProps={{
          style: { width: 120, display: isDisabled ? 'none' : 'inline' },
        }}
      >
        <Spin spinning={isWorking} size="large">
          <JarvislyBankExistingAccountForm {...props} />

          {record?.status === 'in_analysis' ? (
            <div className="w-100 text-center">
              <Alert
                message={translateX('asaas_activate_info', infoFrom, infoTo)}
                type="warning"
              />
            </div>
          ) : null}

          <div
            style={{
              display: 'inline-flex',
              position: isMobile ? 'relative' : 'absolute',
              marginTop: 18,
              width: '100%',
              justifyContent: isMobile ? 'center' : 'left',
              marginBottom: isMobile ? 18 : 0,
            }}
          >
            {record?.apiIntegrated ? (
              <div>
                <Tooltip title={translateWord('api_integrated')}>
                  <div style={{ paddingTop: 6, marginRight: 6 }}>
                    <Icon
                      type={ApiTwoTone}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                  </div>
                </Tooltip>
              </div>
            ) : null}

            <div>
              {record?.status === 'approved' ? (
                <Tag
                  className="text-capitalize"
                  style={{ margin: 4 }}
                  color="green"
                >
                  {translateX('active_account')}
                </Tag>
              ) : (
                <Tag
                  className="text-capitalize"
                  style={{ margin: 4 }}
                  color="gray"
                >
                  {translateX('waiting_for_activation')}
                </Tag>
              )}
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const JarvislyBanksEditModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    setIsWorking,
    refreshDocument,
    document,
    selectedModule,

    selectedRecord,     // bank
    setSelectedRecord,  // bank

    setShowBankEditModal,
    pixArr,
  } = props;
  
  // console.log('JarvislyBanksEditModal:selectedRecord', selectedRecord)

  // local variables I ---------------------------------------------------------
  const banksList = document?.__banks?.length > 0 ? document?.__banks : null;

  // component states ----------------------------------------------------------

  // for 'banks' tab

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body, values }) => {
    setIsWorking(true);

    const bank = bankService.getBankByNumber(body?.selectedBank) || {};
    const bankNumber = bank.number;
    const bankCode = bank.code;
    const agencyCode = `${bankCode}${returnOnlyNumbers(body?.agency)}${
      body?.agencyDigit ? returnOnlyNumbers(body?.agencyDigit) : ''
    }`;
    const accountCode = `${agencyCode}${returnOnlyNumbers(body?.account)}${
      body?.accountDigit ? returnOnlyNumbers(body?.accountDigit) : ''
    }`;

    let idx = banksList.findIndex(
      b => b.accountCode === accountCode && b?._id !== selectedRecord?._id,
    );

    if (idx > -1) {
      appService.message(
        'w',
        translateX('bank_account_already_exists'),
        'add_account',
        5,
      );
      setIsWorking(false);
      return;
    }

    appService.messageDestroy('add_account');

    const updatedPixArr = pixArr.map(item => {
      if(item.status === 'new') {

        const { _id, status, ...rest } = item;
        return rest;
      }
      return item;
    });
    
    const decoratedBody = {
      // profile: document?.dataType,
      accountType: body?.accountType,
      alias: body?.alias,
      bankCode,
      bankNumber,
      bankName: bank?.name,
      bankTitle: bank?.title,

      agency: body?.agency,
      agencyDigit: body?.agencyDigit || null,
      account: body?.account,
      accountDigit: body?.accountDigit,
      agencyCode,
      accountCode,
      note: body?.note,

      pixKeys: updatedPixArr
    };

    const axiosOptions = {
      url: API_BANKS,
      _id: selectedRecord?._id,
      data: decoratedBody,
    };
    await axiosService.put(axiosOptions);

    refreshDocument(null, true).then();
    setShowBankEditModal(false);
    if (selectedModule.name === 'accounts') authService.refreshAccount();
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <JarvislyFormProvider
        name="form-bank-edit"
        document={selectedRecord}
        requiredFields={[
          'selectedBank',
          'accountType',
          'agency',
          'account',
          'accountDigit',
          'alias',
        ]}
        showSubmitButton={false}
        onConfirm={onConfirm}
        autoFocus="selectedBank"
        abortComponentSave={true}
        initialValues={selectedRecord}
      >
        <ModalComponent
          {...props}
          showBirthdate
          updating={isWorking}
          record={selectedRecord}
          afterClose={() => {
            setSelectedRecord(null);
          }}
        />
      </JarvislyFormProvider>

      {/* WRAPPER PIX KEY MODAL */}
      <PixEditModal {...props} />
      {/* WRAPPER PIX KEY MODAL */}
    </>
  );
});

export default JarvislyBanksEditModal;
