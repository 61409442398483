import { TagOutlined } from '@ant-design/icons';
import { Card, Modal } from 'antd';
import JarvislyEntityForm from 'components/jarvisly-components/forms/Items/JarvislyEntityForm';
import {
  JarvislyFormContext,
  JarvislyFormProvider,
} from 'components/jarvisly-components/others/FormComponents';
import { API_ENTITIES } from 'constants/ApiConstant';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import appService from 'services/appService';
import axiosService from 'services/axiosService';
import entityService from 'services/entityService';
import { returnOnlyNumbers, translateX } from 'utils/helpers';

// COMPONENT *******************************************************************

// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showGuestModal,
    setShowGuestModal,
    setSelectedRecord,
    selectedRecord,
    isDisabled,
  } = props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view')
    : !selectedRecord?._id
      ? translateX('new_associate')
      : translateX('edit_associate');

  const { parameters } = useSelector(s => s.moduleSlice);

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (selectedRecord && showGuestModal) {
      form.setFieldsValue(selectedRecord);
      console.log('selectedRecord', selectedRecord);
    }
  }, [selectedRecord, showGuestModal, form]);

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowGuestModal(false);
  };

  const afterClose = () => {
    form.resetFields();
    setSelectedRecord(null);
  };

  // local variables III --------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        // className="title-fixed-with-18 modal-top"
        title={
          <>
            <TagOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showGuestModal}
        width={640}
        okText={translateX('save')}
        onCancel={onClose}
        cancelText={translateX('close')}
        // okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={'primary'}
        // confirmLoading={isWorking}
        afterClose={afterClose}
      >
        <Card
          title={<div className="text-muted">{translateX('customer')}</div>}
        >
          <JarvislyEntityForm
            {...props}
            entityPersonType="person"
            entityPersonDefault="person"
            // context="__entity"
            additionalInfo="required"
          />
        </Card>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const GuestModal = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    document,
    refreshDocument,
    selectedRecord,
    setShowGuestModal,
    selectedEntity,
    entityType,
  } = props;

  // local variables I ---------------------------------------------------------
  // let entityId = selectedRecord?.entityId;

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body, searchedEntity }) => {
    if (!body) return;
  
    try {
      body.dataType = entityType;
      body.dataProfile = 'associate';
      body.uniqueKey = returnOnlyNumbers(body.cpf) || +new Date();
      body.status = document?.status;
  
      const axiosOptionsEntity = {
        url: API_ENTITIES,
        _id: selectedRecord?._id,
        data: body,
      };
  
      const entity = await axiosService.upsert(axiosOptionsEntity);
  
      const associateId = entity?.[0]?._id;
      if (!associateId) return;
  
      if (!Array.isArray(document.associateId)) {
        document.associateId = [];
      }
  
      const associateExists = document.associateId.includes(associateId);
      if (!associateExists) {
        document.associateId.push(associateId);
      }
  
      const axiosOptions = {
        url: '/v1/events',
        _id: document._id,
        data: {
          associateId: document.associateId,
        },
      };
  
      const response = await axiosService.put(axiosOptions);
  
      refreshDocument();
      setShowGuestModal(false);
  
      return response;
    } catch (error) {
      console.error('Erro no onConfirm:', error.message || error);
    }
  };
  
  
  

  // variables III -------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <JarvislyFormProvider
        document={selectedRecord}
        requiredFields={['cpf', 'email','name', 'phone']}
        showSubmitButton={false}
        onConfirm={onConfirm}
        abortComponentSave={true}
      >
        <ModalComponent {...props} />
      </JarvislyFormProvider>
    </>
  );
};

export default GuestModal;
