import { Card, Col, Descriptions, Row } from 'antd';
import { JarvislyNumber } from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const SettingsTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  useTabChild('settings', 'downtime', props);

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------
  const descriptionItems = buildDescriptionItems();
  const descriptionItems2 = buildDescriptionItems2();
  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card title={translateX('Parametros')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12} lg={6}>
            <JarvislyNumber
              label="downtime"
              name={['field1', 'downtime']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'mn'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <JarvislyNumber
              label="alarm_exceeded_min"
              name={['field1', 'alarmExceededMin']}
              min={0}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <JarvislyNumber
              label="alarm_exceeded_max"
              name={['field1', 'alarmExceededMax']}
              min={0}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <JarvislyNumber
              label="calibration_time"
              name={['field1', 'calibrationtime']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'hr'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
        </Row>
      </Card>
      <Card title={translateX('calibration')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12}>
            <JarvislyNumber
              label="minimum_distance"
              name={['field1', 'initialRangeValue']}
              min={0}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
          <Col xs={24} sm={12}>
            <JarvislyNumber
              label="maximal_distance"
              name={['field1', 'finalRangeValue']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'hr'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12}>
            <Descriptions column={2} bordered items={descriptionItems} />
          </Col>
          <Col xs={24} sm={12}>
            <Descriptions column={2} bordered items={descriptionItems2} />
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildDescriptionItems() {
    return [
      // {
      //   key: '1',
      //   label: translateX('maximal_distance'),
      //   children: document?.field1?.finalRangeValue || translateX('n_a'),
      // },
      // {
      //   key: '2',
      //   label: translateX('minimum_distance'),
      //   children: document?.field1?.initialRangeValue || translateX('n_a'),
      // },
      {
        key: '3',
        label: translateX('first_reading'),
        children: dayjs(document?.lastReadAt).fromNow() || translateX('n_a'),
      },
    ];
  }
  function buildDescriptionItems2() {
    return [
      {
        key: '4',
        label: translateX('last_reading'),
        children: dayjs(document?.lastReadAt).fromNow() || translateX('n_a'),
      },
    ];
  }
};

export default SettingsTab;
